import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import Button from "../../Design/Button/Button";

import "./Auth.css";

// Icons
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import { useAuth } from "../../Components/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { resetPasswordRequest } from "../../Utils/Requests/RequestRegister";
import { ISnackbar } from "../../Interfaces/Snackbar";
import { CustomizedSnackbars } from "../../Design/Snackbar/Snackbar";


export default function Login() {
    const { user, loginUser } = useAuth();
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        email: "",
    });

    const [snackBarData, setSnackBarData] = useState<ISnackbar>({
        message: '',
        messageType: '',
        openSnackbar: false,
        key: 0
    });

    const [errorMessages, setErrorMessages] = useState<{error: boolean, message: string}>({
        error: false,
        message: ""
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const loginUserAction = async (e: React.MouseEvent, callback: () => void) => {
        e.preventDefault();
        try {
            const response = await resetPasswordRequest(userData);
            if (response.status === "error") {
                setErrorMessages({
                    error: true,
                    message: response.message
                })
            } else if (response.status === "success") {
                setErrorMessages({
                    error: false,
                    message: response.message
                }) 
            }
        } catch (error) {
            console.log(error);
        }

        callback();
    };

    useEffect(() => {
        if (user.loggedIn) {
            navigate("/");
        }
    }, [user.loaded]);


    return (
        <>
            <div className="auth-form-holder">
                <form className="login-form">
                    <h1>Reset Password</h1>

                    <TextField
                        label="Email"
                        fullWidth
                        variant="standard"
                        required
                        InputProps={{
                            startAdornment: (
                                <EmailIcon sx={{ margin: "0 10px 0 0" }} />
                            ),
                        }}
                        type="email"
                        name="email"
                        onChange={onChange}
                    />


                    <Button
                        text="Send Link"
                        clicked={loginUserAction}
                        icon={undefined}
                    />

                </form>
            </div>
            <CustomizedSnackbars messageType={snackBarData.messageType} message={snackBarData.message} openSnackbar={snackBarData.openSnackbar} key={snackBarData.key} />
        </>
    )
}