import React from 'react'

import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

function AdBlocker() {
    return (
        <div className='error-page-header'>
            <div className='error-page container'>
                <div className='error-page-header' style={{marginBottom: 50}}>
                    <SentimentVeryDissatisfiedIcon />
                </div>
                <span className='error-message'>
                    We rely on ads to keep providing free content. Please consider disabling your ad blocker to support us and continue enjoying our films!
                </span>
            </div>
        </div>
    )
}

export default AdBlocker