import React, { useEffect, useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import AddFilm from './AddFilm/AddFilm';
import EditFilm from './EditFilm/EditFilm';
import DeleteFilm from './DeleteFilm/DeleteFilm';

import "./FilmManager.css";
import { useAuth } from '../../../Components/AuthProvider';

export default function FilmManager() {

  const [verticalTabValue, setVerticalTabValue] = useState<number>(0);
  const navigate = useNavigate();

  const pageList: { [key: number]: string } = {
    0: "addfilm",
    1: "editfilm",
    2: "deletefilm"
  };

  const handleVerticalTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {    
    setVerticalTabValue(newValue);
    navigate(`/Admin/filmpanel/${pageList[newValue]}`, { replace: true });
  };

  return (
    <div className='container' style={{ width: "100%" }}>
      <Grid container spacing={2} className="panel-container-grid">
        <Grid item>
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={verticalTabValue}
            onChange={handleVerticalTabChange}
          >
            <Tab label="Add Film" value={0} className='left-tab' />
            <Tab label="Edit Film" value={1} className='left-tab' />
            <Tab label="Remove Film" value={2} className='left-tab' />
          </Tabs>
        </Grid>
        <div className='AddFilm tab-page' style={{ display: verticalTabValue === 0 ? "flex" : "none" }}>
          <AddFilm />
        </div>
        <div className='EditFilm tab-page' style={{ display: verticalTabValue === 1 ? "flex" : "none" }}>
          <EditFilm />
        </div>
        <div className='RemoveFilm tab-page' style={{ display: verticalTabValue === 2 ? "flex" : "none" }}>
          <DeleteFilm />
        </div>
      </Grid>
    </div>
  )
}
