import React, { createContext, useContext, useEffect, useState } from "react";
import Cookie from "js-cookie";
import { checkUserTokenRequest } from "../Utils/Requests/RequestRegister";

// Define the type for the context

import { IUser } from "../Interfaces/User";
import { IAuthContextType } from "../Interfaces/AuthContextType";

// Initialize the context with the proper type
export const AuthContext = createContext<IAuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {

  const [user, setUser] = useState<IUser>({
    firstName: "",
    lastName: "",
    email: "",
    token: "",
    loggedIn: false,
    verified: false,
    loaded: false,
    accountStatus: ""
  });

  const loginUser = (token: string) => {
    setUser({ ...user, token });

    Cookie.set("token", token);
    verifyUser();
  };

  const logOutUser = () => {
    setUser({ ...user, token: "", loggedIn: false, loaded: true });
    Cookie.remove("token");
  };

  const verifyUser = async () => {
    const token = Cookie.get("token")
    
    if (!token) {
      logOutUser();
      return;
    }

    try {
      const response = await checkUserTokenRequest({
        token: token,
      });

      if (response.status === "error") {
        logOutUser()
      } else if (response.status === "success") {
        setUser({
          firstName: response.user.firstname,
          lastName: response.user.lastname,
          email: response.user.email,
          token: token,
          loggedIn: true,
          verified: false,
          loaded: true,
          accountStatus: response.user.is_admin === 1 ? "Admin" : "",
        })
      }
    } catch (error) {
      console.log(error);
    }
  };

  const authContextValues: IAuthContextType = {
    user: user,
    setUser: setUser,
    loginUser: loginUser,
    logOutUser: logOutUser,
  };

  useEffect(() => {
    verifyUser();
  }, []);

  return (
    <AuthContext.Provider value={authContextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const currentUserContext = useContext(AuthContext);

  if (!currentUserContext) {
    throw new Error("useAuth must be used within <AuthContext.Provider>");
  }

  return currentUserContext;
};
