import React from 'react'

import VideocamOffIcon from '@mui/icons-material/VideocamOff';

import "./NoPage.css"

export default function NoPage() {

  const relevant404Quotes = [
    "We've reached the end of the line. — The Matrix Reloaded (2003)",
    "Sometimes you have to break the rules to set things right. — V for Vendetta (2005)",
    "Every journey has its end. — The Lord of the Rings: The Return of the King (2003)",
    "The end is just the beginning of a new path. — Doctor Strange (2016)",
    "You can’t go back and change the beginning, but you can start where you are and change the ending. — C.S. Lewis: The Lion, The Witch, and The Wardrobe (2005)",
    "It’s not the destination, it’s the journey. — The Secret Life of Walter Mitty (2013)",
    "This is not the end. It is not even the beginning of the end. But it is, perhaps, the end of the beginning. — Winston Churchill's speech, adapted in Darkest Hour (2017)",
    "Sometimes you have to let go of the past to move forward. — The Lion King (1994)",
    "The end of one journey is the start of another. — Harry Potter and the Deathly Hallows: Part 2 (2011)",
    "The road to success is dotted with many tempting parking spaces. — The Shawshank Redemption (1994)",
    "This is where the road ends, my friend. — Mad Max: Fury Road (2015)",
    "In the middle of difficulty lies opportunity. — The Pursuit of Happyness (2006)",
    "Sometimes you have to go back to actually move forward. — Star Trek: Generations (1994)",
    "When you reach the end of your rope, tie a knot and hang on. — The Dark Knight (2008)",
    "All great changes are preceded by chaos. — The Dark Knight Rises (2012)",
    "When you hit the end of the road, you should take the next exit. — Ferris Bueller’s Day Off (1986)",
    "You must unlearn what you have learned. — Star Wars: Episode V - The Empire Strikes Back (1980)",
    "When one door closes, another opens. — The Sound of Music (1965)",
    "Sometimes it takes a leap of faith to get to the other side. — Indiana Jones and the Last Crusade (1989)",
    "The world is full of crossroads, and sometimes you have to make the hardest choice. — Inception (2010)",
    "When you’re at the end of your rope, tie a knot and hang on. — The Princess Bride (1987)",
    "Nothing is impossible; the word itself says 'I’m possible!' — Breakfast at Tiffany’s (1961)",
    "It's not the end of the world, but you can see it from here. — The Hunt for Red October (1990)",
    "Sometimes the end is just the beginning of something new. — The Adjustment Bureau (2011)",
    "The only way to deal with an unfree world is to become so absolutely free that your very existence is an act of rebellion. — The Hunger Games (2012)",
    "We all have to make choices, and sometimes those choices are not easy. — The Godfather (1972)",
    "The end is just another beginning. — The Chronicles of Narnia: Prince Caspian (2008)",
    "You can’t change the past, but you can learn from it. — Back to the Future Part II (1989)",
    "When you reach the end of the road, you can’t go any further. — The Terminator (1984)",
    "Every ending has a new beginning. — The Princess Diaries (2001)",
    "The world is not black and white; it’s a spectrum of grey. — Pulp Fiction (1994)",
    "Sometimes you have to break down to break through. — Fight Club (1999)",
    "Sometimes the right path is not the easiest one. — Pocahontas (1995)",
    "Sometimes you have to be lost to find your way. — Into the Wild (2007)",
    "When things go wrong, don't go with them. — The Matrix (1999)"
  ];


  const getRandomMessage = () => {
    return relevant404Quotes[Math.floor(Math.random() * relevant404Quotes.length)];
  }



  return (
    <div className='error-page-header'>
      <div className='error-page container'>
        <div className='error-page-header'>
          <VideocamOffIcon />
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </div>
        <span className='error-message'>{getRandomMessage()}</span>
      </div>
    </div>
  )
}
