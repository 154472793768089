import React, { useState } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';

import "./Button.css"

export default function Button({
    text,
    clicked,
    icon: Icon,
}: {
    text: string,
    clicked?: (e: React.MouseEvent, callback: () => void) => void,
    icon?: SvgIconComponent
}) {

    const [buttonClicked, setButtonClicked] = useState<boolean>(false)

    const buttonClickedEvent = (e: React.MouseEvent) => {
        if (clicked) {
            setButtonClicked(true)
            clicked(e, () => {
                setButtonClicked(false)
            })
        }
    }

    return (
        <button className={'btn primary ' + (buttonClicked === true ? "enabled" : "")} onClick={buttonClickedEvent} disabled={buttonClicked}>
            <span className='button-inner-data'>
                {Icon && <Icon />} {text}
            </span>
            <span className='button-loader'>
                <RefreshIcon />
            </span>
        </button>
    );
}
