import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../../../Components/AuthProvider'
import { IEpisode, IFilm, IGenres, ISession } from '../../../../Interfaces/Film'
import UploadIcon from '@mui/icons-material/Upload';
import { ISnackbar } from '../../../../Interfaces/Snackbar';
import { CustomizedSnackbars } from '../../../../Design/Snackbar/Snackbar';

import Promp from '../../../../Design/Promp/Promp';
import Crop from '../../../../Design/Crop/Crop';
import Button from '../../../../Design/Button/Button';
import { Area } from 'react-easy-crop';
import getCroppedImg from '../../../../Design/Crop/getCroppedImg';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField, FormControlLabel, Switch } from '@mui/material';
import Route from '../../../../Utils/Routes.json';
import { IPeople } from '../../../../Interfaces/People';
import { getCountries, getGenres, getPeopleDataRequest, AddFilmDataRequest } from '../../../../Utils/Requests/RequestRegister';
import { ICountry } from '../../../../Interfaces/Country';
import CancelIcon from '@mui/icons-material/Cancel';

export default function AddFilm() {

  const { user } = useAuth()

  const [posterImage, setPosterImage] = useState<string | null>()
  const [thumbnailImage, setThumbnailImage] = useState<string | null>()

  const [thumbnailCroppedArea, setThumbnailCroppedArea] = useState<Area | null>(null);
  const [posterCroppedArea, setPosterCroppedArea] = useState<Area | null>(null);


  const [posterImageFile, setPosterImageFile] = useState<File | null>(null)
  const [thumbnailImageFile, setThumbnailImageFile] = useState<File | null>(null)

  const posterCropperRef = useRef<{ getCroppedImage: () => void }>(null);
  const thumbnailCropperRef = useRef<{ getCroppedImage: () => void }>(null);

  const [isThumbnailCropperVisible, setIsThumbnailCropperVisible] = useState(false);
  const [isPosterCropperVisible, setIsPosterCropperVisible] = useState(false);

  const [errorMessages, setErrorMessages] = useState({
    film_name: [],
    film_description: [],
    film_release: [],
    film_country: []
  })

  const [filmData, setFilmData] = useState<IFilm>({
    film_name: '',
    film_description: '',
    film_release: 0,
    film_country: 0,
    genre: [],
    director: [],
    actors: [],
    film_banner: '',
    film_poster: '',
    playlist: false,
    sessions: [],
    film_video: "",
  })

  const [snackBar, setSnackBar] = useState<ISnackbar>({
    message: '',
    messageType: '',
    openSnackbar: false,
    key: 0
  })

  const [actorList, setActorList] = useState<IPeople[]>([])
  const [directorList, setDirectorList] = useState<IPeople[]>([])
  const [filmGenres, setFilmGenres] = useState<IGenres[]>([])
  const [countries, setCountries] = useState<ICountry[]>([])

  const onThumbnailUpload = (file: File | undefined | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setThumbnailImage(reader.result as string);
        setIsThumbnailCropperVisible(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const onPosterUpload = (file: File | undefined | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPosterImage(reader.result as string);
        setIsPosterCropperVisible(true);
      };
      reader.readAsDataURL(file);
    }
  };


  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilmData({ ...filmData, [e.target.name]: e.target.value })
  }

  const thumbnailCrop = async () => {
    if (!thumbnailImage || !thumbnailCropperRef.current || !thumbnailCroppedArea) return;

    try {
      await thumbnailCropperRef.current.getCroppedImage();
      const croppedImage = await getCroppedImg(thumbnailImage, thumbnailCroppedArea);
      if (croppedImage) {
        setThumbnailImageFile(croppedImage);
        setThumbnailImage(URL.createObjectURL(croppedImage));
      }
      setIsThumbnailCropperVisible(false);
    } catch (error) {
      console.error('Error while cropping thumbnail image:', error);
    }
  };

  const posterCrop = async () => {
    if (!posterImage || !posterCropperRef.current || !posterCroppedArea) return;

    try {
      await posterCropperRef.current.getCroppedImage();
      const croppedImage = await getCroppedImg(posterImage, posterCroppedArea);
      if (croppedImage) {
        setPosterImageFile(croppedImage);
        setPosterImage(URL.createObjectURL(croppedImage));
      }
      setIsPosterCropperVisible(false);
    } catch (error) {
      console.error('Error while cropping poster image:', error);
    }
  };

  const searchActor = async (key: string, value: string) => {

    setActorList([])

    try {
      const response = await getPeopleDataRequest(key + value);

      if (response.status === 'success') {

        const data = response.response.response

        if (data) {
          if (data.length > 0) {
            data.forEach((person: IPeople) => {
              setActorList((prev) => [...prev, person])
            })
          } else {
            setSnackBar({
              message: 'Person not found.',
              messageType: 'error',
              openSnackbar: true,
              key: new Date().getTime()
            });
            return;
          }

        } else {
          setSnackBar({
            message: 'Person not found.',
            messageType: 'error',
            openSnackbar: true,
            key: new Date().getTime()
          });
        }

      } else {
        setSnackBar({
          message: response.message,
          messageType: 'error',
          openSnackbar: true,
          key: new Date().getTime()
        });
      }
    } catch (error) {
      setSnackBar({
        message: 'Error while loading person.',
        messageType: 'error',
        openSnackbar: true,
        key: new Date().getTime()
      });
    }
  }

  const searchDirector = async (key: string, value: string) => {

    setDirectorList([])

    try {
      const response = await getPeopleDataRequest(key + value + "&type=Director");

      if (response.status === 'success') {

        const data = response.response.response

        if (data) {
          if (data.length > 0) {
            data.forEach((person: IPeople) => {
              setDirectorList((prev) => [...prev, person])
            })
          } else {
            setSnackBar({
              message: 'Person not found.',
              messageType: 'error',
              openSnackbar: true,
              key: new Date().getTime()
            });
            return;
          }

        } else {
          setSnackBar({
            message: 'Person not found.',
            messageType: 'error',
            openSnackbar: true,
            key: new Date().getTime()
          });
        }

      } else {
        setSnackBar({
          message: response.message,
          messageType: 'error',
          openSnackbar: true,
          key: new Date().getTime()
        });
      }
    } catch (error) {
      setSnackBar({
        message: 'Error while loading person.',
        messageType: 'error',
        openSnackbar: true,
        key: new Date().getTime()
      });
    }
  }

  const loadGenres = async () => {
    try {
      const response = await getGenres();

      if (response.status === 'success') {
        setFilmGenres(response.genres);
      } else {
        setSnackBar({
          message: response.message,
          messageType: 'error',
          openSnackbar: true,
          key: new Date().getTime()
        });
      }
    } catch (error) {
      setSnackBar({
        message: 'Error while loading genres.',
        messageType: 'error',
        openSnackbar: true,
        key: new Date().getTime()
      });
    }
  }

  const loadCountries = async () => {
    try {
      const response = await getCountries();

      if (response.status === 'success') {
        setCountries(response.countries);
      } else {
        setSnackBar({
          message: response.message,
          messageType: 'error',
          openSnackbar: true,
          key: new Date().getTime()
        });
      }
    } catch (error) {
      setSnackBar({
        message: 'Error while loading countries.',
        messageType: 'error',
        openSnackbar: true,
        key: new Date().getTime()
      });
    }
  }


  const AddFilmRequest = async (e: React.MouseEvent, callback: () => void) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("film_name", filmData.film_name)
      formData.append("film_description", filmData.film_description)
      formData.append("film_release", filmData.film_release.toString())
      formData.append("film_country", filmData.film_country.toString())
      formData.append("film_genre", JSON.stringify(filmData.genre))
      formData.append("film_director", JSON.stringify(filmData.director))
      formData.append("film_actors", JSON.stringify(filmData.actors))
      formData.append("playlist", filmData.playlist.toString())
      formData.append("token", user.token)

      if (thumbnailImageFile) {
        formData.append("film_banner", thumbnailImageFile)
      }
      if (posterImageFile) {
        formData.append("film_poster", posterImageFile)
      }

      if (filmData.playlist) {
        formData.append("sessions", JSON.stringify(filmData.sessions))
      } else if (filmData.film_video) {
        formData.append("film_video", filmData.film_video)
      }

      const response = await AddFilmDataRequest(formData);

      if (response.status === 'success') {
        setSnackBar({
          message: 'Film added successfully.',
          messageType: 'success',
          openSnackbar: true,
          key: new Date().getTime()
        })
      }

      if (response.status === 'error') {
        setSnackBar({
          message: response.message,
          messageType: 'error',
          openSnackbar: true,
          key: new Date().getTime()
        })
      }


    } catch (error) {
      setSnackBar({
        message: 'Error while adding film.',
        messageType: 'error',
        openSnackbar: true,
        key: new Date().getTime()
      })
      console.log(error);

    }

    callback()
  }

  const initialize = () => {
    loadGenres()
    loadCountries()
  }

  useEffect(() => {
    initialize()
  }, [])

  return (
    <>
      <div className='film-data'>
        <div className='film-image-holder'>
          <div className='film-thumbnail-upload-holder'>
            {!thumbnailImage && (
              <>
                <UploadIcon />
                <input type="file" accept="image/*" onChange={(e) => onThumbnailUpload(e.target.files?.[0])} />
              </>
            )}
            {thumbnailImage && (
              <img src={thumbnailImage} alt="Thumbnail" onClick={() => setIsThumbnailCropperVisible(true)} />
            )}
          </div>

          <div className='film-poster-upload-holder'>
            {!posterImage && (
              <>
                <UploadIcon />
                <input type="file" accept="image/*" onChange={(e) => onPosterUpload(e.target.files?.[0])} />
              </>
            )}
            {posterImage && (
              <img src={posterImage} alt="Poster" onClick={() => setIsPosterCropperVisible(true)} />
            )}
          </div>
        </div>

        <TextField
          label="Film Name"
          fullWidth
          variant="standard"
          required
          type="text"
          name="film_name"
          onChange={onChange}
          error={!!errorMessages.film_name.length}
          helperText={errorMessages.film_name[0]}
          sx={{ minWidth: 400 }}
          value={filmData.film_name}
        />

        <TextField
          label="Film Description"
          fullWidth
          variant="standard"
          required
          type="text"
          multiline
          name="film_description"
          minRows={4}
          onChange={onChange}
          error={!!errorMessages.film_description.length}
          helperText={errorMessages.film_description[0]}
          sx={{ minWidth: 400 }}
          value={filmData.film_description}
        />

        <TextField
          label="Release Year"
          fullWidth
          variant="standard"
          type="text"
          name="film_release"
          onChange={onChange}
          error={!!errorMessages.film_description.length}
          helperText={errorMessages.film_description[0]}
          sx={{ minWidth: 400 }}
          value={filmData.film_release}
        />
      </div>

      <Autocomplete
        className='people-film-list'
        options={actorList}
        getOptionLabel={(option) => option.people_fullname}
        renderInput={(params) => <TextField variant="standard" {...params} label="Actor" onChange={(e) => {
          searchActor("?firstName=", e.target.value)
        }} />}
        renderOption={(props, option) => (
          <li className='actor-option' {...props}>
            <img src={Route.Domain + "/images/people/" + option.people_picture} alt='profile-picture' className='actor-option-img' />
            <p className='actor-option-name'>{option.people_fullname}</p>
          </li>
        )}
        onChange={(e, newVal) => {
          if (newVal) {
            const actorIds: number[] = newVal
              .filter((actor: IPeople | null | undefined): actor is IPeople => !!actor)
              .reduce((acc: number[], actor: IPeople) => {
                if (actor.id) {
                  acc.push(actor.id);
                }
                return acc;
              }, []);

            setFilmData((prevData) => {
              return {
                ...prevData,
                actors: actorIds,
              };
            });
          }
        }}
        multiple
      />

      <Autocomplete
        className='people-film-list'
        options={directorList}
        getOptionLabel={(option) => option.people_fullname}
        renderInput={(params) => <TextField variant="standard" {...params} label="Director" onChange={(e) => {
          searchDirector("?firstName=", e.target.value)
        }} />}
        renderOption={(props, option) => (
          <li className='actor-option' {...props}>
            <img src={Route.Domain + "/images/people/" + option.people_picture} alt='profile-picture' className='actor-option-img' />
            <p className='actor-option-name'>{option.people_fullname}</p>
          </li>
        )}
        onChange={(e, newVal) => {
          if (newVal) {
            const directorIds: number[] = newVal
              .filter((director: IPeople | null | undefined): director is IPeople => !!director)
              .reduce((acc: number[], director: IPeople) => {
                if (director.id) {
                  acc.push(director.id);
                }
                return acc;
              }, []);

            setFilmData((prevData) => {
              return {
                ...prevData,
                director: directorIds,
              };
            });
          }
        }}
        multiple
      />

      <Autocomplete
        className='people-film-list'
        options={filmGenres}
        getOptionLabel={(option) => option.genre_name}
        renderInput={(params) => <TextField variant="standard" {...params} label="Genres" />}
        renderOption={(props, option) => (
          <li className='actor-option' {...props}>
            <p className='actor-option-name'>{option.genre_name}</p>
          </li>
        )}
        onChange={(e, newVal) => {
          if (newVal) {
            const genreIds: number[] = newVal
              .filter((genre: IGenres | null | undefined): genre is IGenres => !!genre)
              .reduce((acc: number[], genre: IGenres) => {
                if (genre.id) {
                  acc.push(genre.id);
                }
                return acc;
              }, []);

            setFilmData((prevData) => {
              return {
                ...prevData,
                genre: genreIds, // Assuming `genre` is the correct key
              };
            });
          }
        }}
        multiple
      />

      <Autocomplete
        className='people-film-list'
        options={countries}
        getOptionLabel={(option) => option.country_name}
        renderInput={(params) => <TextField variant="standard" {...params} label="Country" />}
        renderOption={(props, option) => (
          <li className='actor-option' {...props}>
            <p className='actor-option-name'>{option.country_name}</p>
          </li>
        )}
        onChange={(e, newVal) => {
          if (newVal) {
            setFilmData((prevData) => {
              return {
                ...prevData,
                film_country: newVal.id
              };
            })
          }
        }}
      />

      <FormControlLabel control={<Switch value={filmData.playlist} />} label="Playlist" sx={{ color: "white" }} onChange={(e: React.SyntheticEvent<Element, Event>, checked: boolean) => {
        setFilmData((prevData) => {
          return {
            ...prevData,
            playlist: checked
          }
        })
      }} />


      {filmData.playlist === true ? (
        <>
          <div className='session-list'>
            <div className='session-header'>
              <h2>Sessions</h2>
              <Button
                text='Add Session'
                clicked={(e, callback) => {
                  const newSessions = filmData.sessions

                  if (!newSessions) return

                  newSessions.push({
                    sessions_name: '',
                    episodes: []
                  })

                  setFilmData((prevData) => ({
                    ...prevData,
                    sessions: newSessions
                  }));
                  callback();
                }}
              />
            </div>
            <ul>
              {filmData.sessions?.map((session: ISession, index: number) => (
                <li key={index} className='episode-list'>
                  <div className='episode-header'>
                    <TextField
                      label={`Session Name ${index + 1}`}
                      sx={{ width: 200 }}
                      variant="standard"
                      required
                      type="text"
                      name={`session_${index + 1}`}
                      onChange={(e) => {

                        if (!filmData.sessions) return

                        const newSessions = [...filmData.sessions];
                        newSessions[index] = {
                          ...newSessions[index],
                          sessions_name: e.target.value
                        };
                        setFilmData((prevData) => ({ ...prevData, sessions: newSessions }));
                      }}
                    />
                    <CancelIcon onClick={() => {

                      if (!filmData.sessions) return

                      const newSessions = [...filmData.sessions];
                      newSessions.splice(index, 1);
                      setFilmData((prevData) => ({ ...prevData, sessions: newSessions }));
                    }}></CancelIcon>
                  </div>
                  <li>
                    <h3 style={{ color: "white" }}>Episodes</h3>
                    <Button
                      text='Add Episode'
                      clicked={(e, callback) => {

                        const newEpisodes = [...session.episodes];

                        newEpisodes.push({
                          episode_name: '',
                          film_video: ''
                        });

                        setFilmData((prevData) => {
                          if (!prevData.sessions) return { ...prevData };

                          const newSessions = [...prevData.sessions];
                          newSessions[index] = {
                            ...newSessions[index],
                            episodes: newEpisodes
                          };

                          return {
                            ...prevData,
                            sessions: newSessions
                          };
                        });



                        callback();
                      }}
                    />
                    <ul>
                      {session.episodes?.map((episode: IEpisode, episodeIndex: number) => (
                        <li key={episodeIndex} className='episode-item'>
                          <TextField
                            label={`Episode Name ${episodeIndex + 1}`}
                            fullWidth
                            variant="standard"
                            required
                            type="text"
                            name={`episode_${episodeIndex + 1}`}
                            onChange={(e) => {

                              episode.episode_name = e.target.value

                            }}
                          />
                          <TextField
                            label={`Episode Video ${episodeIndex + 1}`}
                            fullWidth
                            variant="standard"
                            required
                            type="text"
                            name={`episode_${episodeIndex + 1}`}
                            onChange={(e) => {
                              episode.film_video = e.target.value
                            }}
                          />
                          <CancelIcon onClick={() => {
                            const newEpisodes = [...session.episodes];
                            newEpisodes.splice(episodeIndex, 1);

                            setFilmData((prevData) => {
                              if (!prevData.sessions) return { ...prevData };

                              const newSessions = [...prevData.sessions];
                              newSessions[index] = {
                                ...newSessions[index],
                                episodes: newEpisodes
                              };

                              return {
                                ...prevData,
                                sessions: newSessions
                              };
                            });
                          }}></CancelIcon>
                        </li>
                      ))}
                    </ul>
                  </li>
                </li>
              ))}
            </ul>
          </div>
        </>

      ) : (
        <TextField
          label="Film Video"
          fullWidth
          variant="standard"
          required
          type="text"
          name="film_video"
          onChange={onChange}
          error={!!errorMessages.film_description?.length}
          helperText={errorMessages.film_description?.[0]}
          sx={{ minWidth: 400 }}
          value={filmData.film_video}
        />
      )}

      <Button text='Save' clicked={AddFilmRequest}></Button>

      <Promp
        title='Thumbnail Editor'
        visible={isThumbnailCropperVisible}
        onClose={() => setIsThumbnailCropperVisible(false)}
        onSave={thumbnailCrop}
      >
        {thumbnailImage && (
          <Crop
            ref={thumbnailCropperRef}
            imgSrc={thumbnailImage}
            aspectRatio={16 / 9}
            onCrop={(croppedImage: File) => setThumbnailImageFile(croppedImage)}
            croppedArea={thumbnailCroppedArea}
            setCroppedArea={setThumbnailCroppedArea}
          />
        )}
        {!thumbnailImage && (
          <>
            <input type='file' accept='image/*' onChange={(e) => onThumbnailUpload(e.target.files?.[0])} />
          </>
        )}
        <Button text='Remove' clicked={(e, callback) => {
          setThumbnailImage(null)
          callback()
        }} />
      </Promp>

      <Promp
        title='Poster Editor'
        visible={isPosterCropperVisible}
        onClose={() => setIsPosterCropperVisible(false)}
        onSave={posterCrop}
      >
        {posterImage && (
          <Crop
            ref={posterCropperRef}
            imgSrc={posterImage}
            aspectRatio={9 / 16}
            onCrop={(croppedImage: File) => setPosterImageFile(croppedImage)}
            croppedArea={posterCroppedArea}
            setCroppedArea={setPosterCroppedArea}
          />
        )}
        {!posterImage && (
          <>
            <input type='file' accept='image/*' onChange={(e) => onPosterUpload(e.target.files?.[0])} />
          </>
        )}
        <Button text='Remove' clicked={(e, callback) => {
          setPosterImage(null)
          callback()
          }} />
      </Promp>

      <CustomizedSnackbars
        openSnackbar={snackBar.openSnackbar}
        message={snackBar.message}
        messageType={snackBar.messageType}
        key={snackBar.key}
      />
    </>
  )
}
