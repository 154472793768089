import React from 'react';
import './Promp.css';

import CloseIcon from '@mui/icons-material/Close';

interface PrompProps {
  title: string;
  visible: boolean;
  onClose: (visible: boolean) => void;
  onSave: () => void;
  children: React.ReactNode;
}

const Promp: React.FC<PrompProps> = ({ title, visible, onClose, onSave, children }) => {
  const closePrompt = () => {
    onClose(false);
  }

  const handleClickOutside = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      closePrompt();
    }
  };

  return (
    <div className={`prompt-holder ${visible ? 'visible' : 'hidden'}`} onClick={handleClickOutside}>
      <div className='prompt'>
        <div className='prompt-title'>
          <p>{title}</p>
          <CloseIcon onClick={closePrompt}></CloseIcon>
        </div>
        <div className='prompt-content'>
          {children}
        </div>
        <div className='prompt-actions'>
          <button className='btn-close' onClick={closePrompt}>
            Close
          </button>
          <button className='btn-save' onClick={onSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Promp;
