import React, { useEffect, useState } from 'react'
import { IPeople } from '../../Interfaces/People'
import { getPeopleDataRequest } from '../../Utils/Requests/RequestRegister'
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import ActorCards from '../../Design/ActorCard/ActorCard';
import { TablePagination } from '@mui/material';

import "./Peoples.css"
import { Helmet } from 'react-helmet';

export default function Peoples() {

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [people, setPeople] = useState<IPeople[]>([])

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [totalFilms, setTotalFilms] = useState<number>(0);

  // Fetching data from API

  const getPeople = async () => {
    const fullName = searchParams.get('firstName') || '';
    const born = searchParams.get('born') || '';
    const gender = searchParams.get('gender') || '';
    const country = searchParams.get('country') || '';
    const id = searchParams.get('id') || '';
    const type = searchParams.get('type') || '';
    const page = parseInt(searchParams.get('page') || '1', 10);
    const limit = parseInt(searchParams.get('limit') || '25', 10);

    try {
      const result = await getPeopleDataRequest(`?firstName=${fullName}&born=${born}&gender=${gender}&country=${country}&id=${id}&type=${type}&page=${page}&limit=${limit}`);

      if (result.success === false) {
        console.log(result.message);
        return;
      }

      setPeople(result.response.response);
      setTotalFilms(result.response.count);
    } catch (error) {
      console.error("An error occurred while fetching the data: ", error);
    }
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getPeople();
  }, [page, rowsPerPage, location.search]); // Include location.search to refetch when query params change

  return (
    <>
      <Helmet>
        <title>Search Actor</title>
      </Helmet>
      <div className='container'>
        <div className='people-list-container'>
          <div className='people-list'>
            {people.length > 0 && (
              <ActorCards actorsList={people} />
            )}
          </div>
          <TablePagination
            component="div"
            count={totalFilms}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ color: "var(--secondaryColor)" }}
            style={{ width: "fit-content", margin: "auto" }}
          />
        </div>
      </div></>
  )
}
