import React, { useState, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg';
import { Area } from 'react-easy-crop/types';  // Import Area type only

import "./Crop.css";

interface CropProps {
    imgSrc: string;
    aspectRatio: number;
    onCrop: (croppedImg: File) => void;
    croppedArea: Area | null;  // Include croppedArea in props
    setCroppedArea: React.Dispatch<React.SetStateAction<Area | null>>;  // Include setCroppedArea in props
}

const Crop = forwardRef(({ imgSrc, onCrop, aspectRatio, croppedArea, setCroppedArea }: CropProps, ref) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    // Function to handle cropping completion
    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels);  // Update cropped area state
    }, [setCroppedArea]);    

    // Function to get cropped image
    const getCroppedImage = useCallback(async () => {
        if (croppedArea && imgSrc) {
            try {
                const croppedImage = await getCroppedImg(imgSrc, croppedArea);  // Call getCroppedImg with imgSrc and croppedArea
                if (croppedImage) {
                    onCrop(croppedImage);  // Call onCrop callback with cropped image
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, [croppedArea, imgSrc, onCrop]);

    // Expose getCroppedImage function via ref
    useImperativeHandle(ref, () => ({
        getCroppedImage,
    }));

    return (
        <div className='crop-holder'>
            <div className='crop-container'>
                <Cropper
                    image={imgSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspectRatio}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                />
            </div>
        </div>
    );
});

export default Crop;
