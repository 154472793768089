import React, { useEffect, useState } from 'react'

import "./Footer.css"
import { Link } from 'react-router-dom'

function Footer() {

    return (
        <div className='footer'>
            <div className='footer-header'>
                <span>{window.location.host}</span>
            </div>
            <div className='footer-content-list'>
                <div className='footer-content'>
                    <div className='footer-content-title'>
                        <span>About</span>
                    </div>
                    <div className='footer-content-list'>
                        <div className='footer-content-item'>
                            <Link to={"TermOfService"}>Terms of Use</Link>
                        </div>
                        <div className='footer-content-item'>
                            <Link to={"PrivacyPolice"}>Privacy Policy</Link>
                        </div>
                        <div className='footer-content-item'>
                            <Link to={"ContactUs"}>Contact Us</Link>
                        </div>
                    </div>
                </div>
                <div className='footer-content'>
                    <div className='footer-content-title'>
                        <span>Useful Links</span>
                    </div>
                    <div className='footer-content-list'>
                        <div className='footer-content-item'>
                            <Link to={"actors"}>Actors</Link>
                        </div>
                        <div className='footer-content-item'>
                            <Link to={"directors"}>Directors</Link>
                        </div>
                        <div className='footer-content-item'>
                            <Link to={"films"}>Films</Link>
                        </div>
                    </div>
                </div>
                <div className='footer-content'>
                    <div className='footer-content-title'>
                        <span>Socials</span>
                    </div>
                    <div className='footer-content-list'>
                        <div className='footer-content-item'>
                            <Link to={"https://www.tiktok.com/@fxfilms.net"}>Tiktok</Link>
                        </div>
                        <div className='footer-content-item'>
                            <Link to={"https://www.instagram.com/_fxfilms.net_/"}>Instagram</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer