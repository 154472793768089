import React, { useEffect, useRef } from 'react';

interface CircularProgressProps {
    score: number; // Score from 0 to 10
    color: string; // RGB or RGBA color string for the progress color
    size?: number; // Optional: size of the circle
    thickness?: number; // Optional: thickness of the circle
    backgroundColor?: string; // Optional: background color of the circle
    sx?: any;
}

const CircularProgress: React.FC<CircularProgressProps> = ({ score, color, backgroundColor, size = 100, thickness = 10, sx }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        if (!context) return;

        // Set canvas size
        canvas.width = size;
        canvas.height = size;

        // Calculate the progress angle based on the score (0-10 scale to 0-100% scale)
        const percentage = (score / 10) * 100;
        const endAngle = (percentage / 100) * 2 * Math.PI;

        // Center and radius of the circle
        const centerX = size / 2;
        const centerY = size / 2;
        const radius = (size - thickness) / 2;

        // Clear the canvas
        context.clearRect(0, 0, size, size);

        // If no backgroundColor is provided, calculate the background as the color with 0.5 transparency
        const bgColor = backgroundColor || `${color.slice(0, color.lastIndexOf(','))}, 0.2)`; // Adjusted background opacity

        // Draw filled background circle
        context.beginPath();
        context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        context.fillStyle = bgColor; // Fill color for background with transparency
        context.fill();

        // Draw the progress outline circle
        context.beginPath();
        context.arc(centerX, centerY, radius, -Math.PI / 2, endAngle - Math.PI / 2); // Start at the top
        context.lineWidth = thickness;
        context.strokeStyle = color; // Solid color for the progress
        context.lineCap = 'round'; // Rounded ends for the progress circle
        context.stroke();

        // Draw score text in the center
        context.font = `bold ${size * 0.25}px Arial`;
        context.fillStyle = "white";
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(`${score.toFixed(1)}`, centerX, centerY);

    }, [score, color, backgroundColor, size, thickness]);

    return <canvas ref={canvasRef} style={sx ? sx : {}} />;
};

export default CircularProgress;
