import React from 'react';
import "./PrivacyPolicy.css";
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy</h1>
            <p><em>Last Updated: 23/10/2024</em></p>
            <p>
                At FxFilms ("the Website"), we respect your privacy and are committed to protecting 
                your personal information. This Privacy Policy explains how we collect, use, and 
                safeguard your information when you use FxFilms.
            </p>

            <h2>1. Information We Collect</h2>
            <ul>
                <li>We collect personal information such as your email, name, and hashed password when you create an account.</li>
                <li>We automatically collect certain technical information, including your IP address, browser type, and operating system, when you use FxFilms.</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <ul>
                <li>To provide, operate, and maintain the Website.</li>
                <li>To manage your account and respond to your requests or inquiries.</li>
                <li>To improve the Website's performance and user experience.</li>
                <li>To send you updates and notifications related to FxFilms, if applicable.</li>
            </ul>

            <h2>3. Sharing Your Information</h2>
            <ul>
                <li>We do not sell or share your personal information with third parties, except as required by law.</li>
                <li>We may disclose information to comply with legal obligations, protect our rights, or enforce our Terms of Service.</li>
            </ul>

            <h2>4. Data Security</h2>
            <p>
                We take appropriate measures to protect your personal information from unauthorized 
                access, use, or disclosure. However, please note that no method of data transmission 
                or storage is 100% secure.
            </p>

            <h2>5. Cookies and Tracking Technologies</h2>
            <p>
                FxFilms uses cookies and similar tracking technologies to improve user experience and 
                analyze usage patterns. You can control your cookie preferences through your browser settings.
            </p>

            <h2>6. Children's Privacy</h2>
            <p>
                While FxFilms is accessible to all ages, we do not knowingly collect personal information 
                from children under the age of 13 without parental consent.
            </p>

            <h2>7. Changes to the Privacy Policy</h2>
            <p>
                We reserve the right to update this Privacy Policy at any time. Any changes will be 
                posted on this page, and your continued use of the Website after such changes 
                signifies your acceptance of the revised policy.
            </p>

            <h2>8. Contact Us</h2>
            <p>
                If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@fxfilms.net">support@fxfilms.net</a>
            </p>
        </div>
    );
}

export default PrivacyPolicy;
