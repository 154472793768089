import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import "./Films.css";
import { useAuth } from '../../Components/AuthProvider';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TablePagination, TextField } from '@mui/material';
import FilmCards from '../../Design/FilmCard/FilmCard';
import { getCountries, getGenres, searchFilmDataRequest } from '../../Utils/Requests/RequestRegister';
import { IFilm, IGenres } from '../../Interfaces/Film';
import { ICountry } from '../../Interfaces/Country';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Films() {
  const { user } = useAuth();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // State variables
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [films, setFilms] = useState<IFilm[]>([]);
  const [totalFilms, setTotalFilms] = useState<number>(0);
  const [selectedGenre, setSelectedGenre] = useState<number[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<number[]>([]);
  const [liked, setLiked] = useState<string>('');
  const [watched, setWatched] = useState<string>('');
  const [genres, setGenres] = useState<IGenres[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [reloadAd, setReloadAd] = useState<number>(0)

  // Fetch genres and countries only once on initial mount
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [genresResponse, countriesResponse] = await Promise.all([getGenres(), getCountries()]);
        if (genresResponse.status === "success") setGenres(genresResponse.genres);
        if (countriesResponse.status === "success") setCountries(countriesResponse.countries);
      } catch (error) {
        console.error('Failed to load initial data:', error);
      }
    };
    fetchInitialData();
  }, []);

  const getFilms = useCallback(async () => {
    try {

      setReloadAd(reloadAd + 1)

      const params = new URLSearchParams(location.search); // Fetches directly from the URL

      // Extract parameters directly from searchParams
      const filmName = params.get('film_name') || '';
      const id = params.get('id') || '';
      const filmPlaylist = params.get('film_playlist') || 'false';
      const watched = params.get('watched') || '';
      const liked = params.get('liked') || '';
      const sort = params.get('sort') || 'new';
      const filmCountry = params.get('film_country') || '';
      const filmGenre = params.get('film_genre') || '';
      const token = user.token;

      // Fetch data from the request based on all params set above
      const result = await searchFilmDataRequest(
        `?film_name=${filmName}&id=${id}&page=${page + 1}&limit=${rowsPerPage}&watched=${watched}&liked=${liked}&sort=${sort}&film_country=${filmCountry}&playlist=false&film_genre=${filmGenre}&token=${token}`
      );

      // Update state only if request is successful
      if (result.status === "success") {
        setFilms(result.films.map((film: IFilm) => ({ film })));
        setTotalFilms(result.total);
      }
    } catch (error) {
      console.error('Error fetching films:', error);
    }
  }, [page, rowsPerPage, user.token, location.search]); // Only triggers on dependency changes

  // Effect for fetching films when dependencies change
  useEffect(() => {
    getFilms();
  }, [getFilms]);

  // Update search params in the URL
  const updateSearchParams = (newPage: number, newRowsPerPage: number, genres?: number[], country?: number[], liked?: string, watched?: string) => {
    searchParams.set('page', (newPage + 1).toString());
    searchParams.set('limit', newRowsPerPage.toString());
    if (genres && genres.length > 0) searchParams.set('film_genre', genres.join(','));
    if (country && country.length > 0) searchParams.set('film_country', country.join(','));
    if (liked) searchParams.set('liked', liked);
    if (watched) searchParams.set('watched', watched);
    setSearchParams(searchParams);
  };

  // Pagination and filter change handlers with debouncing
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    updateSearchParams(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    updateSearchParams(0, newRowsPerPage);
  };

  const handleGenreChange = (event: SelectChangeEvent<string[]>) => {
    const value = Array.isArray(event.target.value) ? event.target.value.map(Number) : [];
    setSelectedGenre(value);
    updateSearchParams(page, rowsPerPage, value);
  };

  const handleCountryChange = (event: SelectChangeEvent<string[]>) => {
    const value = Array.isArray(event.target.value) ? event.target.value.map(Number) : [];
    setSelectedCountry(value);
    updateSearchParams(page, rowsPerPage, selectedGenre, value);
  };

  const handleLikedChange = (event: SelectChangeEvent<string>) => {
    setLiked(event.target.value);
    updateSearchParams(page, rowsPerPage, selectedGenre, selectedCountry, event.target.value, watched);
  };

  const handleWatchedChange = (event: SelectChangeEvent<string>) => {
    setWatched(event.target.value);
    updateSearchParams(page, rowsPerPage, selectedGenre, selectedCountry, liked, event.target.value);
  };

  const handleFilmName = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.value.length < 2) {
      return
    }

    searchParams.set("film_name", event.target.value)
    setSearchParams(searchParams);
  }

  useEffect(() => {
    const loadAdScript = (id: string, key: string, width: number, height: number) => {
      const adContainer = document.getElementById(id);
      if (!adContainer) {
        console.warn(`Ad container with id ${id} not found.`);
        return;
      }

      (window as any).atOptions = {
        key: key,
        format: 'iframe',
        height: height,
        width: width,
        params: {}
      };

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//chancesarmlessimpulse.com/${key}/invoke.js`;
      adContainer.appendChild(script);

      script.onerror = () => {
        window.location.href = '/adblocker';
      };
    };

    // Load ad1 first, and delay ad2 slightly
    loadAdScript("ad1", "31b0ede2142ef4b12caeae1653920e5b", 728, 90);

    setTimeout(() => {
      loadAdScript("ad2", "ba3529dec34d34428f52c2a503bfe193", 468, 60);
    }, 200); // 200ms delay for ad2


    return () => {
      ["ad1", "ad2"].forEach(id => {
        const adContainer = document.getElementById(id);
        if (adContainer) adContainer.innerHTML = ""; // Clear ad scripts on unmount
      });
    };
  }, [reloadAd]);

  return (
    <>
      <Helmet>
        <title>Search Films</title>
      </Helmet>
      <div className='container'>
        <div className='films-list-container'>
          <div className="film-search-element">
            <TextField
              label="Search Films"
              variant="outlined"
              fullWidth
              onChange={handleFilmName}
            />
          </div>
          <div className='search-parms'>
            <FormControl fullWidth>
              <InputLabel>Genres</InputLabel>
              <Select multiple value={selectedGenre.map(String)} onChange={handleGenreChange}>
                {genres.map((genre) => (
                  <MenuItem key={genre.id} value={genre.id.toString()}>
                    {genre.genre_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Countries</InputLabel>
              <Select multiple value={selectedCountry.map(String)} onChange={handleCountryChange}>
                {countries.map((country) => (
                  <MenuItem key={country.id} value={country.id.toString()}>
                    {country.country_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Liked</InputLabel>
              <Select value={liked} onChange={handleLikedChange}>
                <MenuItem value="">All</MenuItem>
                <MenuItem value="true">Liked</MenuItem>
                <MenuItem value="false">Not Liked</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Watched</InputLabel>
              <Select value={watched} onChange={handleWatchedChange}>
                <MenuItem value="">All</MenuItem>
                <MenuItem value="true">Watched</MenuItem>
                <MenuItem value="false">Not Watched</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div id="ad1" style={{ margin: "auto" }}></div>
          <div className='films-list'>
            <FilmCards films={films} horizontal={true} />
          </div>
          <div id="ad2" style={{ margin: "auto" }}></div>
          <TablePagination
            component="div"
            count={totalFilms}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{color: "white", margin: "auto"}}
          />
        </div>
      </div>
    </>
  );
}

export default Films;
