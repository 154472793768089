import React, { useEffect, useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import AddPeople from './AddPeople/AddPeople';
import EditPeople from './EditPeople/EditPeople';
import DeletePeople from './DeletePeople/DeletePeople';

import "./PeopleManager.css";
import { useAuth } from '../../../Components/AuthProvider';

export default function PeopleManager() {

  const [verticalTabValue, setVerticalTabValue] = useState<number>(0);
  const navigate = useNavigate();

  const pageList: { [key: number]: string } = {
    0: "addpeople",
    1: "editpeople",
    2: "deletepeople"
  };

  const handleVerticalTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {    
    setVerticalTabValue(newValue);
    navigate(`/Admin/peoplepanel/${pageList[newValue]}`, { replace: true });
  };

  return (
    <div className='container' style={{ width: "100%" }}>
      <Grid container spacing={2} className="panel-container-grid">
        <Grid item>
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={verticalTabValue}
            onChange={handleVerticalTabChange}
          >
            <Tab label="Add People" value={0} className='left-tab' />
            <Tab label="Edit People" value={1} className='left-tab' />
            <Tab label="Remove People" value={2} className='left-tab' />
          </Tabs>
        </Grid>
        <div className='AddActor tab-page' style={{ display: verticalTabValue === 0 ? "flex" : "none" }}>
          <AddPeople />
        </div>
        <div className='Editactor tab-page' style={{ display: verticalTabValue === 1 ? "flex" : "none" }}>
          <EditPeople />
        </div>
        <div className='RemoveActor tab-page' style={{ display: verticalTabValue === 2 ? "flex" : "none" }}>
          <DeletePeople />
        </div>
      </Grid>
    </div>
  )
}
