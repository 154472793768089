import React, { useEffect, useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useWindowDimensions } from "../../Components/useWindowsDimensions";

import Profile from "./Profile/Profile";
import Settings from "./Settings/Settings";
import History from './History/History';

import "./Index.css";
import { useAuth } from '../../Components/AuthProvider';

export default function Index() {

    const { user } = useAuth()

    const { height, width } = useWindowDimensions();
    const gridTabOrientation = width >= 900 ? "vertical" : "horizontal";

    const [verticalTabValue, setVerticalTabValue] = useState<number>(0);
    const { page } = useParams();
    const navigate = useNavigate();

    const pageList: { [key: number]: string } = {
        0: "profile",
        1: "settings",
        2: "history"
    };

    const getPageKeyFromValue = (value: string): string | null => {
        for (const [key, val] of Object.entries(pageList)) {
            if (val === value) {
                return key;
            }
        }
        return null;
    };

    const handleVerticalTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (page) {
            setVerticalTabValue(newValue);
            navigate(`/user/${pageList[newValue]}`, { replace: true });

            const pageKey = getPageKeyFromValue(page);

            if (pageKey === null) {
                return;
            }

            const currentTabValue = parseInt(pageKey);
            setVerticalTabValue(currentTabValue);
        }
    };

    useEffect(() => {
        if (page) {
            const pageKey = getPageKeyFromValue(page);
            if (pageKey === null) {
                return;
            }

            const currentTabValue = parseInt(pageKey);
            setVerticalTabValue(currentTabValue);
        } else {
            navigate(`/user/${pageList[0]}`, { replace: true });
        }
    }, [page]);


    useEffect(() => {

        if (user.loaded === false) return

        if (!user) {
            navigate('/');
        }
    }, [user.loaded]);

    return (
        <div className='container' style={{ marginTop: 200 }}>
            <Grid container spacing={2} id="settings-grid" className='user-settings-holder'>
                <Grid item>
                    <Tabs
                        orientation={gridTabOrientation}
                        variant="scrollable"
                        value={verticalTabValue}
                        onChange={handleVerticalTabChange}
                        className='admin-panel-tab-list'
                    >
                        <Tab label="Profile" value={0} className='left-tab' />
                        <Tab label="Settings" value={1} className='left-tab' />
                        <Tab label="History" value={2} className='left-tab' />
                    </Tabs>
                </Grid>
                <div className='tab-page' style={{ display: verticalTabValue === 0 ? "flex" : "none" }}>
                    <Profile />
                </div>
                <div className='tab-page' style={{ display: verticalTabValue === 1 ? "flex" : "none" }}>
                    <Settings />
                </div>
                <div className='tab-page' style={{ display: verticalTabValue === 1 ? "flex" : "none" }}>
                    <History />
                </div>
            </Grid>
        </div>
    )
}
