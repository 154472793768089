import { useAuth } from "../../Components/AuthProvider";
import Routes from "../Routes.json"

export function get(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const data = fetch(Routes.Domain + url);

        data.then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    resolve(data);
                });
            } else {
                reject({ success: false, response: response });
            }
        });
    });
}

export function post(url: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
        const formData = data instanceof FormData;

        fetch(Routes.Domain + url, {
            method: "POST",
            headers: formData ? undefined : { "Content-Type": "application/json" },
            body: formData ? data : JSON.stringify(data)
        }).then((response) => {
            return response.json()
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject({success: false, response: error});
        })
    });
}