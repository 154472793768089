import React from 'react';
import './App.css';
import Button from './Design/Button/Button';
import MailIcon from '@mui/icons-material/Mail';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { AuthProvider } from './Components/AuthProvider';

// Pages


import LoginPage from './Pages/Auth/Login';
import ForgotPasswordPage from './Pages/Auth/ForgotPassword';
import RegisterPage from './Pages/Auth/Register';
import AdScriptLoader from '../src/Components/AdReloader'
import Home from './Pages/Home/Home';
import NoPage from './Pages/NoPage/NoPage';
import Navbar from './Design/Navbar/Navbar';
import Admin from './Pages/Admin/Admin';
import People from './Pages/People/People';
import Film from './Pages/Film/Film';
import Films from './Pages/Films/Films';
import Series from './Pages/Series/Series';
import Footer from './Design/Footer/Footer';
import Peoples from './Pages/Peoples/Peoples';
import ResetPassword from './Pages/Auth/ResetPassword';
import TermOfService from './Pages/TermOfService/TermOfService';
import ContactUs from './Pages/ContactUs/ContactUs';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import IndexSettings from './Pages/UserSettings/index'
import UserVeirfyMail from "./Pages/Auth/UserVeirfyMail"
import NewNavbar from './Design/Navbar/NewNavbar';
import AdBlocker from './Pages/AdBlocker/AdBlocker';


// Theme 

const getCSSVariableValue = (variable: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};


const primaryColor = getCSSVariableValue('--primaryColor');
const secondaryColor = getCSSVariableValue('--darkRed');

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          "label": {
            color: "white"
          },
          margin: "15px 0",
          ".MuiInputBase-root": {
            color: "white"
          },
          '& .MuiInput-underline:before': { borderBottomColor: 'white!important' },
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "white",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)"
          }
        }
      }
    }
  }
});

function AppContent() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <NewNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Peoples" element={<Peoples />} />
          <Route path="People/:id" element={<People />} />
          <Route path="Film/:id" element={<Film />} />
          <Route path="Films" element={<Films />} />
          <Route path="Series" element={<Series />} />
          <Route path="*" element={<NoPage />} />
          <Route path='AdBlocker' element={<AdBlocker />} />

          <Route path="TermOfService" element={<TermOfService />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />

          <Route path="Auth">
            <Route path="Login" element={<LoginPage />} />
            <Route path="ForgotPassword" element={<ForgotPasswordPage />} />
            <Route path="Register" element={<RegisterPage />} />
            <Route path="ResetPassword" element={<ResetPassword />} />
            <Route path="VerifyMail" element={<UserVeirfyMail />} />
            <Route path="*" element={<NoPage />} />
          </Route>

          <Route path="Admin">
            <Route index element={<Admin />} />
            <Route path=":page" element={<Admin />} />
            <Route path=":page/:panel" element={<Admin />} />
            <Route path="*" element={<NoPage />} />
          </Route>

          <Route path="User">
            <Route index element={<IndexSettings />} />
            <Route path=":page" element={<IndexSettings />} />
            <Route path=":page/:panel" element={<IndexSettings />} />
            <Route path="*" element={<IndexSettings />} />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default AppContent;
