import React from 'react';
import { IPeople } from '../../Interfaces/People';
import { Skeleton } from '@mui/material';

import Routes from "../../Utils/Routes.json"

import "./ActorCards.css"
import { Link } from 'react-router-dom';

interface IActorList {
    actorsList: IPeople[];
}

function ActorCards({ actorsList }: IActorList) {

    return (
        <>
            {actorsList.length > 0 ? (
                actorsList.map((actor: IPeople) => {
                    return (
                        <Link to={"/people/" + actor.id} state={{actor: actor}} className="actor-card" key={actor.id} style={{ backgroundImage: `url(${Routes.Domain + Routes.imagesPeople + actor.people_picture})` }}>
                            <div className="card-body">
                                <h5 className="card-title">{actor.people_fullname}</h5>
                            </div>
                        </Link>
                    )
                })
            ) : (
                [...Array(25)].map((_, index) => (
                    <Skeleton className='actor-skeleton-span' sx={{ background: "gray", margin: 0 }} width={200} height={300} key={index}>

                    </Skeleton>
                ))
            )}
        </>
    );
}

export default ActorCards;