import React, { useEffect, useState } from 'react'

import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { IUser } from '../../../Interfaces/User';
import Button from '../../../Design/Button/Button';
import { useAuth } from '../../../Components/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { sendMailVerificationRequest } from '../../../Utils/Requests/RequestRegister';

export default function Profile() {

  const { user } = useAuth()
  const navigator = useNavigate()


  const [userData, setUserData] = useState<IUser>(user)

  const [userPassword, setUserPassword] = useState({
    currentPassword: '',
    newPassword1: '',
    newPassword2: ''
  })


  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e: React.MouseEvent, callback: () => void) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append('user_firstname', userData.firstName);
      formData.append('user_lastname', userData.lastName);
      formData.append('user_email', userData.email);
      formData.append('user_token', userData.token);
      formData.append('user_currentPassword', userPassword.currentPassword);
      formData.append('user_newPassword1', userPassword.newPassword1);
      formData.append('user_newPassword2', userPassword.newPassword2);

    } catch (error) {
      console.error('Error while submitting:', error);
    }

    callback()
  };

  const handleMailVerification = async () => {
    try {
      const response = await sendMailVerificationRequest({
        token: user.token
      });

      if (response.status === 'success') {
        alert('Verification email has been sent.')
      } else if (response.status === 'error') {
        alert(response.message)
      }
    } catch (error) {
      console.error('Error while sending verification email:', error);
    }
  }

  useEffect(() => {
    if (user.loaded === false) return

    if (user.loggedIn === false) {
      navigator("/")
    }

    setUserData(user)
  }, [user.loaded])

  return (
    <div className='user-page'>
      <TextField
        label="Firstname"
        fullWidth
        variant="standard"
        required
        type="text"
        name="firstName"
        onChange={onChange}
        sx={{ minWidth: 400 }}
        value={userData.firstName}
      />

      <TextField
        label="Lastname"
        fullWidth
        variant="standard"
        required
        type="text"
        name="lastName"
        onChange={onChange}
        sx={{ minWidth: 400 }}
        value={userData.lastName}
      />

      <TextField
        label="Email"
        fullWidth
        variant="standard"
        required
        type="text"
        name="email"
        onChange={onChange}
        sx={{ minWidth: 400 }}
        value={userData.email}
      />
      {user.verified === false ? (
        <span className='user-verified-error'>User not verified <a onClick={handleMailVerification}>Click here</a></span>
      ) : (
        <span className='user-verified-succsess'>user verified</span>
      )}

      <div className='header-title' style={{ margin: "20px 0" }}>
        <p>Password</p>
      </div>

      <TextField
        label="Current Password"
        fullWidth
        variant="standard"
        required
        type="password"
        onChange={(e) => {
          setUserPassword({ ...userPassword, currentPassword: e.target.value })
        }}
        sx={{ minWidth: 400 }}
        value={userPassword.currentPassword}
      />

      <TextField
        label="New Password"
        fullWidth
        variant="standard"
        required
        type="password"
        onChange={(e) => {
          setUserPassword({ ...userPassword, newPassword1: e.target.value })
        }}
        sx={{ minWidth: 400 }}
        value={userPassword.newPassword1}
      />

      <TextField
        label="Repeat Password"
        fullWidth
        variant="standard"
        required
        type="password"
        onChange={(e) => {
          setUserPassword({...userPassword, newPassword2: e.target.value })
        }}
        sx={{ minWidth: 400 }}
        value={userPassword.newPassword2}
      />

      <Button text='Save' clicked={handleSubmit} />
    </div>

  )
}
