import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';


// Interface

import { ISnackbar } from '../../Interfaces/Snackbar';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function CustomizedSnackbars({ messageType, message, openSnackbar }: ISnackbar) {
  const [open, setOpen] = useState(openSnackbar);

  const handleClose = (event: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setOpen(false);
  };  
  

  const getMessageSeverity = () => {
    switch (messageType) {
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'info':
        return 'info';
      case 'success':
      default:
        return 'success';
    }
  };

  const getMessageText = () => {
    return message
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={getMessageSeverity()} sx={{ width: '100%' }}>
          {getMessageText()}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export { CustomizedSnackbars };