import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { IEpisode, IFilm, IGenres, ISession } from '../../Interfaces/Film';
import { createUserPlaylistRequest, getUserPlaylistRequest, getCountries, searchFilmDataRequest, voteFilmRequest, deletePlaylistRequest, addFilmToPlaylistRequest, removeFilmToPlaylistRequest, getGenres } from '../../Utils/Requests/RequestRegister';
import Routes from "../../Utils/Routes.json";

import { Helmet } from 'react-helmet';

import "./Film.css";
import { useAuth } from '../../Components/AuthProvider';
import { IPeople } from '../../Interfaces/People';
import ActorCards from '../../Design/ActorCard/ActorCard';
import { useDragScroll } from '../../Components/Dragger';
import { ICountry } from '../../Interfaces/Country';
import { IPlayList, IUserFilmPlayList, IUserPlayList } from '../../Interfaces/UserPlaylist';
import { ISnackbar } from '../../Interfaces/Snackbar';
import { CustomizedSnackbars } from '../../Design/Snackbar/Snackbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';

import AdScriptLoader from '../../Components/AdReloader';

// Icons

// Bookmarks

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

// Like

import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

// Dislike

import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

// Delete Icon

import DeleteIcon from '@mui/icons-material/Delete';

// Plus Icon

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Add Icom

import AddIcon from '@mui/icons-material/Add';

// Remove Icon

import RemoveIcon from '@mui/icons-material/Remove';
import Promp from '../../Design/Promp/Promp';
import ButtonCustom from '../../Design/Button/Button';
import { TextField } from '@mui/material';
import { addFilmSessionToLocalStorage, addFilmToLocalStorage, getFilmFromLocalStorage, getFilmSessionFromLocalStorage } from '../../Components/filmLocalStorage';
import Cancel from '@mui/icons-material/Cancel';

type Episode = {
  id: number;
  session_id: number;
  episode_name: string;
  film_video: string;
  film_id: number;
};

type Session = {
  id: number;
  film_id: number;
  sessions_name: string;
  episodes: Episode[];
};

type FilmData = {
  sessions: Session[];
};

var player: any = null

const Film = () => {
  const { user } = useAuth()
  const { id } = useParams();
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<any>(null);

  const [openPlaylistModal, setOpenPlaylistModal] = useState<boolean>(false)
  const [openCreatePlaylistModal, setCreateOpenPlaylistModal] = useState<boolean>(false)

  const [selectedEpisode, setSelectedEpisode] = useState<number>(1);
  const [selectedSession, setSelectedSession] = useState<ISession>();
  const [loadedSession, setLoadedSession] = useState<number>(1);

  const [anchorSessionEl, setAnchorSessionEl] = useState<null | HTMLElement>(null);
  const sessionOpen = Boolean(anchorSessionEl);

  const [anchorEpisodeEl, setAnchorEpisodeEl] = useState<null | HTMLElement>(null);
  const episodeOpen = Boolean(anchorEpisodeEl);
  const [reloadAd, setReloadAd] = useState(0)
  const lastPressedTime = useRef<number | null>(null);

  const [snackBarData, setSnackBarData] = useState<ISnackbar>({
    message: '',
    messageType: '',
    openSnackbar: false,
    key: 0
  })

  const [filmData, setFilmData] = useState<IFilm>({
    id: 0,
    film_name: "",
    film_description: "",
    film_release: 0,
    film_country: 0,
    genre: [],
    director: [],
    actors: [],
    film_banner: "",
    film_poster: "",
    playlist: false,
    sessions: [],
    film_video: "",
    like: 0,
    dislike: 0,
    views: 0,
  });

  const [filmCountry, setFilmCountry] = useState<ICountry>({
    id: 0,
    country_name: "",
    country_code: "",
  });

  const [filmGenres, setFilmGenres] = useState<IGenres[]>([])

  const [userPlaylist, setUserPlaylist] = useState<IPlayList>({
    playList: [],
    filmPlayList: []
  });

  const [filmTime, setFilmTime] = useState<string>("")
  const [actorRefDragger] = useDragScroll()
  const [directorRefDragger] = useDragScroll()

  const [backgroundImagePos, setBackgroundImagePos] = useState<number>(0)

  const getActionButton = (playlist: IUserPlayList) => {
    const filmId = filmData.id;
    const playListId = playlist.id;

    const isFilmInPlayList = (): boolean => {
      return userPlaylist.filmPlayList.some((playlistItem: IUserFilmPlayList) => {
        return playlistItem.film_id === filmId && playlistItem.playlist_id === playListId;
      });
    };

    if (!filmId || !playListId) {
      return (
        <></>
      )
    }

    if (isFilmInPlayList()) {
      // Render button for removing the film from the playlist
      return (
        <button onClick={() => {
          removeFilmFromPlaylist(filmId, playListId)
        }}>
          <RemoveIcon />
        </button>
      );
    } else {
      // Render button for adding the film to the playlist
      return (
        <button onClick={() => {
          addFilmToPlaylist(filmId, playListId)
        }}>
          <AddIcon />
        </button>
      );
    }
  };

  const addFilmToPlaylist = async (filmId: number, playListId: number) => {
    try {
      const response = await addFilmToPlaylistRequest({
        film_id: filmId,
        playlist_id: playListId,
        token: user.token
      })

      if (response.status === "error") {
        setSnackBarData({ message: response.message, messageType: "error", openSnackbar: true, key: Math.random() })
        return
      }


      setSnackBarData({ message: response.message, messageType: "success", openSnackbar: true, key: Math.random() })


      loadUserPlayList()
    } catch (error) {
      console.log(error);
    }
  }

  const removeFilmFromPlaylist = async (filmId: number, playListId: number) => {
    try {
      const response = await removeFilmToPlaylistRequest({
        film_id: filmId,
        playlist_id: playListId,
        token: user.token
      })

      if (response.status === "error") {
        setSnackBarData({ message: response.message, messageType: "error", openSnackbar: true, key: Math.random() })
        return
      }

      setSnackBarData({ message: response.message, messageType: "success", openSnackbar: true, key: Math.random() })

      loadUserPlayList()
    } catch (error) {
      console.log(error);
    }
  }

  const deletePlaylist = async (playlistId: number) => {
    try {
      const response = await deletePlaylistRequest({
        playlist_id: playlistId,
        token: user.token
      })

      if (response.status === "error") {
        setSnackBarData({ ...snackBarData, openSnackbar: true, message: response.message, key: Math.random(), messageType: 'error' })
        return
      }

      setSnackBarData({ ...snackBarData, openSnackbar: true, message: response.message, key: Math.random(), messageType: 'success' })
      loadUserPlayList()


    } catch (error) {
      console.log(error);
    }
  }

  const loadUserPlayList = async () => {

    try {
      const response = await getUserPlaylistRequest(`?token=${user.token}`)

      if (response.status === "error") {
        setSnackBarData({ ...snackBarData, openSnackbar: true, message: response.message, key: Math.random(), messageType: 'error' })
        return
      }

      setUserPlaylist(response.response)

    } catch (error) {
      console.log(error);
    }
  }

  const createPlayList = async () => {

    const titleElement = document.getElementById("create-playlist-title") as HTMLInputElement | null;

    if (!titleElement) {
      return;
    }

    const createPlaylistTitle = titleElement.value;


    if (!createPlaylistTitle) {
      setSnackBarData({
        openSnackbar: true,
        messageType: "error",
        message: "Something went wrong",
        key: 0
      })
      return
    }

    try {
      const response = await createUserPlaylistRequest({
        playListName: createPlaylistTitle,
        token: user.token
      })

      if (response.status === "error") {
        setSnackBarData({
          message: response.message,
          messageType: "error",
          openSnackbar: true,
          key: Math.random()
        })
        return
      }

      setSnackBarData({ ...snackBarData, openSnackbar: true, message: "Playlist added", key: Math.random(), messageType: 'success' })
      loadUserPlayList()
    } catch (error) {
      console.log(error);
    }
  }

  const searchFilm = async (id: number) => {
    try {
      const response = await searchFilmDataRequest(`?id=${id}&token=${user.token}&include_data=true`);
      if (response.status === 'error') {
        setSnackBarData({
          openSnackbar: true,
          messageType: "error",
          message: "Something went wrong",
          key: Math.random()
        })
        return;
      }
      setFilmData(() => {
        return response.films[0]
      });


      const playlist = response.films[0].film_playlist === "true" ? true : false


      loadCountries(response.films[0].film_country)

      var timeStamp: number = 0

      if (playlist === false) {

        var time = getFilmFromLocalStorage(response.films[0].id)

        if (time) {
          timeStamp = time
        }

        loadPlayer(response.films[0].film_video, id, timeStamp)
      } else {

        var sesion: any = getFilmSessionFromLocalStorage(response.films[0].id)

        const session: ISession[] = response.films[0].sessions

        if (sesion) {
          const episodes: IEpisode[] = session[sesion.session].episodes
          episodes[sesion.episode].active = true
          loadPlayer(episodes[sesion.episode].film_video, id, parseInt(sesion.timestamp))
        }
      }

      setFilmData((prevData) => {
        return {
          ...prevData,
          playlist: playlist
        }
      })

    } catch (error) {
      console.error('Error searching film:', error);
    }
  };

  const changePlayer = async (videoUrl: string) => {

    setReloadAd(reloadAd + 1)

    try {
      if (!playerRef.current) return;


      playerRef.current.src({ src: Routes.CDN + "/" + videoUrl, type: "video/mp4" });
      playerRef.current.load();

    } catch (error) {
      console.log(error);
    }
  };

  const loadPlayer = async (url: string, id: number, timeStamp: number) => {
    if (!videoRef.current) return;

    setReloadAd(reloadAd + 1)

    const videoOptions = {
      sources: [{ src: Routes.CDN + "/" + url, type: "video/mp4" }],
      autoplay: false,
      controls: true,
      fluid: true,
      playbackRates: [0.5, 1, 1.5, 2],
    };

    // Cleanup the existing player instance if it exists
    if (playerRef.current) {
      playerRef.current.dispose(); // Dispose of the previous instance
    }

    const player = (window as any).videojs(videoRef.current, videoOptions);

    // Configure ad plugin
    player.ima({
      adTagUrl: 'https://securepubads.g.doubleclick.net/gampad/ads?env=vp&gdfp_req=1&output=vast&iu=/1234/video-demo&sz=400x300&unviewed_position_start=1&ciu_szs=728x90,300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dlinear&correlator=',
      adsManagerLoadedCallback: () => {
        console.log('Ads Manager Loaded');
      },
    });

    player.on('loadedmetadata', () => {
      if (!player) return;

      const videoLength = player.duration();
      if (!videoLength) return;

      // Set the playback starting time
      player.currentTime(timeStamp);

      // Format the video length and update state
      const hours = Math.floor(videoLength / 3600);
      const minutes = Math.floor((videoLength % 3600) / 60);
      const seconds = Math.floor(videoLength % 60);
      let formattedLength = '';
      if (hours > 0) formattedLength += `${hours}h `;
      if (minutes > 0 || hours > 0) formattedLength += `${minutes}m `;
      formattedLength += `${seconds}s`;
      setFilmTime(formattedLength);
    });

    player.on('ready', () => {
      if (!player) return;
      player.ima.requestAds();
    });

    // Assign the player instance to the ref
    playerRef.current = player;
  };

  const voteFilm = async (like: boolean, dislike: boolean) => {
    try {
      const response = await voteFilmRequest({
        liked: like,
        disliked: dislike,
        token: user.token,
        filmId: filmData?.id
      });

      if (response.status === "error") {
        setSnackBarData({
          openSnackbar: true,
          messageType: "error",
          message: response.message,
          key: Math.random()
        })
        return;
      }

      setFilmData((prevState) => {
        if (!prevState) {
          return prevState;
        }

        if (!filmData) {
          return prevState;
        }

        return {
          ...prevState,
          liked: like,
          disliked: dislike,
          like: response.data.like,
          dislike: response.data.dislike
        };

      });

    } catch (error) {
      console.error('Error voting film:', error);
    }
  }

  const loadCountries = async (countryId: number) => {
    try {
      const response = await getCountries();

      if (response.status === "error") {
        console.error('Error loading countries:', response.message);
        return;
      }

      const countries: ICountry[] = response.countries;

      if (countryId) {
        const country: ICountry | undefined = countries.find((country) => country.id == countryId);

        if (country) {
          setFilmCountry({
            id: country.id,
            country_name: country.country_name,
            country_code: country.country_code // Ensure to include all necessary properties of ICountry
          });
        }
      }
    } catch (error) {
      console.error('Error loading countries:', error);
    }
  };

  const getGenreFromId = (genreId: number) => {
    const genre: IGenres | undefined = filmGenres.find((genre: IGenres) => {
      return genre.id == genreId
    })


    if (genre) {
      return genre.genre_name
    }

    return ''
  }

  const loadFilmGenres = async () => {
    try {
      const genres = await getGenres();

      if (genres.status === "error") {
        return;
      }

      setFilmGenres(genres.genres)

    } catch (error) {
      console.error('Error loading genres:', error);
    }
  }

  const openPlaylistModalHandle = (visibility: boolean) => {
    setOpenPlaylistModal(visibility)
  }

  const openCreatePlaylistModalHandle = (visibility: boolean) => {
    setCreateOpenPlaylistModal(visibility)
  }

  const handleSessionClick = (session: ISession, sessionIndex: number) => {
    setSelectedSession(session);
    setLoadedSession(sessionIndex)
  };

  const handleEpisodeClick = (sessionIndex: number, episodeIndex: number) => {
    if (!filmData.sessions) return;


    changePlayer(filmData.sessions[sessionIndex].episodes[episodeIndex].film_video);
    setSelectedEpisode(episodeIndex);

    filmData.sessions.map((session: ISession) => {
      session.episodes.map((episode: IEpisode) => {
        episode.active = false;
      })
    })


    filmData.sessions[sessionIndex].episodes[episodeIndex].active = true
  };

  const toggleFilm = () => {
    if (playerRef) {
      if (playerRef.current.paused()) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }

  const forWardFilm = (value: number) => {
    const videoLength = playerRef.current?.duration();

    if (!videoLength) {
      return;
    }

    const newTime = Math.min(playerRef.current.currentTime() + value, videoLength);

    playerRef.current.currentTime(newTime);
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    let key = event.key

    // Normalize space key to avoid logging empty space
    if (key === ' ') {
      key = 'Space';
    }

    const currentTime = new Date().getTime();

    // Avoid adding repeated keys with debounce mechanism (500ms)
    if (lastPressedTime.current && currentTime - lastPressedTime.current < 500) {
      return;
    }

    lastPressedTime.current = currentTime;

    switch (key) {
      case "Space":
        toggleFilm()
        event.preventDefault()
        break;
      case "ArrowRight":
        forWardFilm(10)
        event.preventDefault()
        break
      case "ArrowLeft":
        forWardFilm(-10)
        event.preventDefault()
        break
      default:
        break;
    }

  };

  const handleUnload = () => {

    if (filmData.playlist) {
      if (videoRef.current && filmData.id && selectedSession && selectedEpisode) {
        console.log(filmData.id, selectedSession, selectedEpisode, videoRef.current.currentTime);

        addFilmSessionToLocalStorage(filmData.id, 1, selectedEpisode, videoRef.current.currentTime)
      }
    } else {
      if (videoRef.current && filmData.id) {
        const currentTime = videoRef.current.currentTime;
        addFilmToLocalStorage(filmData.id, currentTime);
      }
    }
  };

  useEffect(() => {
    if (id && user.loaded && filmData.id === 0) {

      searchFilm(parseInt(id))
      loadFilmGenres()
      loadUserPlayList();
    }
  }, [user.loaded, id]);

  useEffect(() => {
    window.onscroll = () => {
      const scrollPos = window.scrollY
      setBackgroundImagePos(scrollPos / 2)
    }
  }, [])

  useEffect(() => {
    // Add event listeners
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('beforeunload', handleUnload);

    // Cleanup function to remove the event listeners
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  useEffect(() => {
    const loadAdScript = (id: string, key: string, width: number, height: number) => {
      const adContainer = document.getElementById(id);
      if (!adContainer) {
        console.warn(`Ad container with id ${id} not found.`);
        return;
      }

      console.log(`Loading ad script for ${id}`);

      // Define ad options globally
      (window as any).atOptions = {
        key: key,
        format: 'iframe',
        height: height,
        width: width,
        params: {}
      };

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//chancesarmlessimpulse.com/${key}/invoke.js`;
      adContainer.appendChild(script);

      script.onerror = () => {
        window.location.href = '/adblocker';
      };
    };

    // Load ad1 first, and delay ad2 slightly
    loadAdScript("ad1", "31b0ede2142ef4b12caeae1653920e5b", 728, 90);

    setTimeout(() => {
      loadAdScript("ad2", "ba3529dec34d34428f52c2a503bfe193", 468, 60);
    }, 500); // 200ms delay for ad2


    return () => {
      ["ad1", "ad2"].forEach(id => {
        const adContainer = document.getElementById(id);
        if (adContainer) adContainer.innerHTML = ""; // Clear ad scripts on unmount
      });
    };
  }, [reloadAd]);

  return (
    <>
      <Helmet>
        <title>{(filmData.film_name || '') + " " + (filmData.film_release || 0)}</title>
        <meta name="title" content={filmData.film_name || ''} />
        <meta name="description" content={filmData.film_description || ''} />
        <meta name="keywords" content={filmData.film_description || ''} />
        <meta property="og:title" content={filmData.film_name || ''} />
        <meta property="og:description" content={filmData.film_description || ''} />
        <meta property="og:image" content={Routes.Domain + Routes.imagesFilm + (filmData.film_banner || '')} />
        <meta property="og:url" content={"https://fxfilms.net/film/" + (filmData.id || '0')} />
      </Helmet>
      <>

        <script async data-cfasync="false" src="//chancesarmlessimpulse.com/02df13ae05471911ea5534d38ff621d4/invoke.js"></script>
        <div className='film-video-background'>
          <div className='background-image' style={{ backgroundImage: `url(${Routes.Domain + Routes.imagesFilm + filmData?.film_banner})`, top: backgroundImagePos }}></div>
          <div className='image'></div>
        </div>


        <div className='film-video-data'>
          <div className='poster-holder'>
            <img src={Routes.Domain + Routes.imagesFilm + filmData?.film_poster} alt="Film Poster"></img>
          </div>
          <div className='film-holder'>
            <video ref={videoRef} className="video-js vjs-big-play-centered film-player" onChange={(e) => {
            }} />
            {filmData.playlist === true && (
              <>
                <div className='playlist-data-holder'>
                  <div className='playlist-session-holder'>
                    <Button
                      id="fade-button"
                      aria-controls={sessionOpen ? 'fade-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={sessionOpen ? 'true' : undefined}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorSessionEl(event.currentTarget)
                      }}

                    >
                      Sessions
                    </Button>
                    <Menu
                      id="playlist-menu"
                      MenuListProps={{
                        'aria-labelledby': 'fade-button',
                      }}
                      anchorEl={anchorSessionEl}
                      open={sessionOpen}
                      onClose={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorSessionEl(null)
                      }}
                      TransitionComponent={Fade}
                    >
                      {filmData.sessions?.map((session, index) => (
                        <MenuItem
                          className={selectedSession === session ? "session-id selected" : "session-id"}
                          key={index}
                          onClick={() => handleSessionClick(session, index)}
                        >
                          Session {index + 1}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                  <div className='playlist-episode-holder'>
                    <Button
                      id="fade-button"
                      aria-controls={episodeOpen ? 'fade-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={episodeOpen ? 'true' : undefined}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEpisodeEl(event.currentTarget)
                      }}

                    >
                      Episodes
                    </Button>
                    <Menu
                      id="playlist-menu"
                      MenuListProps={{
                        'aria-labelledby': 'fade-button',
                      }}
                      anchorEl={anchorEpisodeEl}
                      open={episodeOpen}
                      onClose={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEpisodeEl(null)
                      }}
                      TransitionComponent={Fade}
                    >
                      {filmData !== null && selectedSession !== undefined && (
                        selectedSession.episodes?.map((episode: IEpisode, index) => (
                          <MenuItem
                            className={episode.active ? "episode-id selected" : "episode-id"}
                            key={index}
                            onClick={() => handleEpisodeClick(loadedSession, index)}
                          >
                            {episode.episode_name}
                          </MenuItem>
                        ))
                      )}
                    </Menu>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className='film-data-holder'>
          <div id="ad1" style={{ margin: "auto", width: "fit-content" }}></div>
          <div className='container'>
            <div className='film-full-inside'>
              <div className='film-full-title'>
                <div className='film-title-views'>
                  <span className="film-title">{filmData?.film_name + ` (${filmData?.film_release})`}</span>
                  <span className="film-views">Views : {filmData?.views}</span>
                </div>
                <div className='film-actions'>
                  <div className='like-dislike-holder'>
                    <div className={"like-dislike " + (filmData?.liked ? "active" : "")} onClick={(e) => {
                      voteFilm(true, false)
                    }}>
                      <div className='like-dislike-icon'>
                        {filmData?.liked ? (
                          <ThumbUpAltIcon />
                        ) : (
                          <ThumbUpOffAltIcon />
                        )}
                      </div>
                      <span className='like-dislike-count'>{filmData?.like}</span>
                    </div>
                    <div className={"like-dislike " + (filmData?.disliked ? "active" : "")} onClick={(e) => {
                      voteFilm(false, true)
                    }}>
                      <div className='like-dislike-icon'>
                        {filmData?.disliked ? (
                          <ThumbDownAltIcon />
                        ) : (
                          <ThumbDownOffAltIcon />
                        )}
                      </div>
                      <span className='like-dislike-count'>{filmData?.dislike}</span>
                    </div>
                  </div>
                  <div className='bookmark-holder' onClick={() => {
                    openPlaylistModalHandle(true)
                  }}>
                    <BookmarkBorderOutlinedIcon />
                  </div>
                </div>
              </div>
              <div className='movies-full-inside-desc'>
                <div className='movie-fill-inside-desc-data genre'>
                  <span>Genres: </span>
                  {filmData?.genre && (
                    filmData.genre.map((genre: number, index: number) => {
                      return (
                        <Link to={`/films?genre=${genre}`} className='data' key={index}>{getGenreFromId(genre)}</Link>
                      )
                    })
                  )}
                </div>
                <div className='movie-fill-inside-desc-data release'>
                  <span>Release: </span>
                  <p className='data'>{filmData?.film_release}</p>
                </div>
                <div className='movie-fill-inside-desc-data description'>
                  <span>Description: </span>
                  <p className='data' style={{ color: "white", fontSize: 18 }}>{filmData.film_description}</p>
                </div>
                <div className='movie-fill-inside-desc-data description'>
                  <span>Country: </span>
                  <Link to={`/films?country=${filmCountry?.id}`} className='data'>{filmCountry?.country_name}</Link>
                </div>
                <div className='movie-fill-inside-desc-data description'>
                  <span>Leangth: </span>
                  <p className='data'>{filmTime}</p>
                </div>
              </div>
            </div>
            <div className='film-classes'>
              <div className='header-title'>
                <p>Actors</p>
              </div>
              <div className='film-card-list' ref={actorRefDragger} style={{ height: "fit-content" }}>
                {filmData && filmData.actors && Array.isArray(filmData.actors) && filmData.actors.length > 0 && (
                  <ActorCards actorsList={filmData.actors as IPeople[]} />
                )}
              </div>
            </div>
            <div className='film-classes'>
              <div className='header-title'>
                <p>Directors</p>
              </div>
              <div className='film-card-list' ref={directorRefDragger} style={{ height: "fit-content" }}>
                {filmData && filmData.director && Array.isArray(filmData.director) && filmData.director.length > 0 && (
                  <ActorCards actorsList={filmData.director as IPeople[]} />
                )}
              </div>
            </div>
          </div>
          <div id="ad2" style={{ margin: "auto", width: "fit-content"  }}></div>
        </div>


        <Promp
          title='Playlist'
          visible={openPlaylistModal}
          onClose={() => {
            openPlaylistModalHandle(false)
          }}
          onSave={() => {

          }}
        >

          <div className='film-add-playlist-modal'>
            {userPlaylist.playList.length > 0 && userPlaylist.playList.map((playList: IUserPlayList, index: number) => {
              return (
                <>
                  <div key={index} className='playlist-item'>
                    <div className='playlist-item-holder'>
                      <span>{playList.playlist_name}</span>
                      <div className='action-icons'>
                        <button onClick={() => {
                          if (playList.id) {
                            deletePlaylist(playList.id)
                          }
                        }}>
                          <DeleteIcon />
                        </button>
                        {getActionButton(playList)}
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
            <div key={0} className='playlist-item'>
              <div className='playlist-item-holder'>
                <span>Create Playlist</span>
                <div className='action-icons'>
                  <button onClick={() => {
                    openPlaylistModalHandle(false)
                    openCreatePlaylistModalHandle(true)

                  }}>
                    <AddCircleOutlineIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Promp>

        <Promp
          title='Playlist'
          visible={openCreatePlaylistModal}
          onClose={() => {
            openCreatePlaylistModalHandle(false)
            openPlaylistModalHandle(true)
          }}
          onSave={() => {
            createPlayList()
            openCreatePlaylistModalHandle(false)
            openPlaylistModalHandle(true)
          }}
        >

          <div className='film-add-playlist-modal'>
            <TextField
              label='Playlist Name'
              sx={{
                width: "90%",
                margin: "20px auto"
              }}
              id='create-playlist-title'
            />
          </div>
        </Promp>

        <CustomizedSnackbars messageType={snackBarData.messageType} message={snackBarData.message} openSnackbar={snackBarData.openSnackbar} key={snackBarData.key} />
      </></>
  );
};

export default Film;