import { IFilm } from "../Interfaces/Film";

const addFilmToLocalStorage = (filmId: number, timestamp: number) => {
    localStorage.setItem(`film_${filmId}_timestamp`, timestamp.toString());
}

const getFilmFromLocalStorage = (filmId: number): number | null => {
    const timestamp = localStorage.getItem(`film_${filmId}_timestamp`);
    return timestamp ? parseInt(timestamp) : null;
}


const addFilmSessionToLocalStorage = (filmId: number, sessionId: number, episodeId: number, timestamp: number) => {
    localStorage.setItem(`film_${filmId}`, JSON.stringify({
        session: sessionId,
        episode: episodeId,
        timestamp: timestamp.toString()
    }))
}

const getFilmSessionFromLocalStorage = (filmId: number) => {
    const sessionData = localStorage.getItem(`film_${filmId}`);
    return sessionData ? JSON.parse(sessionData) : null;
}


export {
    addFilmToLocalStorage,
    getFilmFromLocalStorage,
    addFilmSessionToLocalStorage,
    getFilmSessionFromLocalStorage,
}