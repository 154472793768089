import React, { useEffect, useState, useRef } from 'react';
import { IPeople } from '../../../../Interfaces/People';
import { ICountry } from '../../../../Interfaces/Country';
import { useAuth } from '../../../../Components/AuthProvider';
import { addPeopleDataRequest, getCountries } from '../../../../Utils/Requests/RequestRegister';
import { CustomizedSnackbars } from '../../../../Design/Snackbar/Snackbar';
import { styled, lighten, darken } from '@mui/system';
import Button from '../../../../Design/Button/Button';
import UploadIcon from '@mui/icons-material/Upload';
import Crop from '../../../../Design/Crop/Crop';
import Promp from '../../../../Design/Promp/Promp';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import getCroppedImg from '../../../../Design/Crop/getCroppedImg';
import { Area } from 'react-easy-crop';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.mode === 'light'
    ? lighten(theme.palette.primary.light, 0.85)
    : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const AddPeople = () => {
  const { user } = useAuth();
  const cropperRef = useRef<{ getCroppedImage: () => void }>(null);

  const [peopleData, setPeopleData] = useState<IPeople>({
    people_fullname: '',
    people_birth: '',
    people_description: '',
    people_gender: '',
    people_country: 0,
    people_picture: '',
    people_type: ''
  });

  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [countryList, setCountryList] = useState<ICountry[]>([]);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);

  const [snackBarData, setSnackBarData] = useState<{
    message: string;
    messageType: string;
    openSnackbar: boolean;
    key: number;
  }>({
    message: '',
    messageType: '',
    openSnackbar: false,
    key: 0
  });

  const [errorMessages, setErrorMessages] = useState<{
    firstname: string[];
    lastname: string[];
    birth: string[];
    description: string[];
  }>({
    firstname: [],
    lastname: [],
    birth: [],
    description: []
  });

  const [isCropperVisible, setIsCropperVisible] = useState(false);

  useEffect(() => {
    if (user.loaded === false) return;
    loadCountries();
  }, [user.loaded]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPeopleData({ ...peopleData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.MouseEvent, callback: () => void) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('people_fullname', peopleData.people_fullname);
      formData.append('people_birth', peopleData.people_birth);
      formData.append('people_description', peopleData.people_description);
      formData.append('people_gender', peopleData.people_gender);
      formData.append('people_country', peopleData.people_country.toString());
      formData.append('people_type', peopleData.people_type);
      formData.append('token', user.token);

      if (imageFile) {
        formData.append('people_picture', imageFile);
      }

      const response = await addPeopleDataRequest(formData);

      setSnackBarData({
        message: response.message,
        messageType: response.status === 'success' ? 'success' : 'error',
        openSnackbar: true,
        key: new Date().getTime()
      });

    } catch (error) {
      console.error('Error while submitting:', error);
    }

    callback()
  };

  const loadCountries = async () => {
    try {
      const countries = await getCountries();

      if (countries.status === 'success') {
        setCountryList(countries.countries);
      } else {
        setSnackBarData({
          message: countries.message,
          messageType: 'error',
          openSnackbar: true,
          key: new Date().getTime()
        });
      }

    } catch (error) {
      console.error('Error while loading countries:', error);
    }
  };

  const onImageUpload = (file: File | undefined | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgSrc(reader.result as string);
        setIsCropperVisible(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCrop = async () => {
    if (!imgSrc || !cropperRef.current || !croppedArea) return;

    try {
      await cropperRef.current.getCroppedImage();
      const croppedImage = await getCroppedImg(imgSrc, croppedArea);
      if (croppedImage) {
        setImageFile(croppedImage);
        setImgSrc(URL.createObjectURL(croppedImage));
      }
      setIsCropperVisible(false);
    } catch (error) {
      console.error('Error while cropping image:', error);
    }
  };

  const countryOptions = countryList.map((option: ICountry) => ({
    firstLetter: /[0-9]/.test(option.country_code[0].toUpperCase()) ? '0-9' : option.country_code[0].toUpperCase(),
    label: option.country_code[0].toUpperCase(),
    ...option,
  }));

  const formatBirthDate = (date: string) => {
    // Implement date formatting logic here
    return date; // Placeholder, replace with actual formatting
  };

  return (
    <>
      <div className='human-data-holder'>
        <div className='human-picture-upload-holder' style={{ maxWidth: 200 }}>
          {!imgSrc && (
            <>
              <UploadIcon />
              <input type="file" accept="image/*" onChange={(e) => onImageUpload(e.target.files?.[0])} />
            </>
          )}
          {imgSrc && (
            <>
              <img src={imgSrc} alt="Selected" onClick={() => setIsCropperVisible(true)} />
            </>
          )}
        </div>

        <TextField
          label="Firstname"
          fullWidth
          variant="standard"
          required
          type="text"
          name="people_fullname"
          onChange={onChange}
          error={!!errorMessages.firstname.length}
          helperText={errorMessages.firstname[0]}
          sx={{ minWidth: 400 }}
          value={peopleData.people_fullname}
        />

        <TextField
          label="Birth Date (YYYY-MM-DD)"
          fullWidth
          variant="standard"
          required
          type="text"
          name="people_birth"
          onChange={onChange}
          error={!!errorMessages.birth.length}
          helperText={errorMessages.birth[0]}
          value={formatBirthDate(peopleData.people_birth)}
        />

        <TextField
          label="Description"
          fullWidth
          variant="standard"
          required
          multiline
          minRows={4}
          type="text"
          name="people_description"
          onChange={onChange}
          error={!!errorMessages.description.length}
          helperText={errorMessages.description[0]}
          value={peopleData.people_description}
        />

        <FormControl fullWidth>
          <InputLabel>Gender</InputLabel>
          <Select
            variant="standard"
            value={peopleData.people_gender}
            onChange={(e) => setPeopleData({ ...peopleData, people_gender: e.target.value })}
            name='people_gender'
          >
            <MenuItem value={"Female"}>Female</MenuItem>
            <MenuItem value={"Male"}>Male</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            variant="standard"
            value={peopleData.people_type}
            onChange={(e) => setPeopleData({ ...peopleData, people_type: e.target.value })}
            name='people_type'
          >
            <MenuItem value={"Director"}>Director</MenuItem>
            <MenuItem value={"Actor"}>Actor</MenuItem>
          </Select>
        </FormControl>

        <Autocomplete
          fullWidth
          options={countryOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.country_name}
          renderInput={(params) => <TextField variant='standard' {...params} label="Country" />}
          onChange={(event, newValue) => {
            if (newValue) {
              setPeopleData({ ...peopleData, people_country: newValue.id });
            } else {
              setPeopleData({ ...peopleData, people_country: 0 });
            }
          }}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />

        <Button text='Save' clicked={handleSubmit} />
      </div>

      <Promp
        title='Image Editor'
        visible={isCropperVisible}
        onClose={() => setIsCropperVisible(false)}
        onSave={handleCrop}
      >
        {imgSrc && (
          <Crop
            ref={cropperRef}
            imgSrc={imgSrc}
            aspectRatio={9 / 16}
            onCrop={(croppedImage: File) => setImageFile(croppedImage)}
            croppedArea={croppedArea}
            setCroppedArea={setCroppedArea}
          />
        )}

        {!imgSrc && (
          <>
            <input type='file' accept='image/*' onChange={(e) => onImageUpload(e.target.files?.[0])} />
          </>
        )}
        <Button text='Remove' clicked={() => setImgSrc(null)} />
      </Promp>

      <CustomizedSnackbars
        messageType={snackBarData.messageType}
        message={snackBarData.message}
        openSnackbar={snackBarData.openSnackbar}
        key={snackBarData.key}
      />
    </>
  );
};

export default AddPeople;
