export function NumberFormat(value: number): string {
    const absValue = Math.abs(value);
    const suffixes = ['', 'K', 'M', 'B', 'T'];

    // If the absolute value is less than 1000, return it as is
    if (absValue < 1000) {
        return absValue.toString();
    }

    // Determine the magnitude of the number
    const magnitude = Math.floor(Math.log10(absValue) / 3);

    // Divide the value by the magnitude to get the formatted value
    const formattedValue = absValue / Math.pow(10, magnitude * 3);

    // Format the value with one decimal place
    const formattedString = formattedValue.toFixed(1);

    return formattedString + suffixes[magnitude];
}