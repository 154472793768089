import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import Button from "../../Design/Button/Button";
import { ISnackbar } from "../../Interfaces/Snackbar";
import { CustomizedSnackbars } from "../../Design/Snackbar/Snackbar";
import PasswordIcon from '@mui/icons-material/Password';
import { useAuth } from "../../Components/AuthProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resetPasswordRequest } from "../../Utils/Requests/RequestRegister";

function ResetPassword() {

    const { user, loginUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    const [passwordReseted, setPasswordReseted] = useState<boolean>(true)

    const [userData, setUserData] = useState({
        password1: "",
        password2: "",
        token: token
    });


    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const [snackBarData, setSnackBarData] = useState<ISnackbar>({
        message: '',
        messageType: '',
        openSnackbar: false,
        key: 0
    });


    const resetPasswordAction = async (e: React.MouseEvent, callback: () => void) => {
        e.preventDefault();
        try {
            const response = await resetPasswordRequest(userData);
            if (response.status === "error") {
                if (response.errors) {
                    setSnackBarData({
                        message: response.message,
                        messageType: "error",
                        openSnackbar: true,
                        key: new Date().getTime()
                    });
                }
            } else if (response.status === "success") {
                loginUser(response.token);
                navigate("/");
            }
        } catch (error) {
            console.log(error);
        }

        callback();
    };


    useEffect(() => {
        if (user.loggedIn) {
            navigate("/");
        }
    }, [user.loaded]);

    return (
        <>
            {passwordReseted ? (
                <div className="auth-form-holder">
                    <form className="login-form">
                        <h1>Reset Password</h1>

                        <TextField
                            label="Password"
                            fullWidth
                            variant="standard"
                            required
                            type="password"
                            InputProps={{
                                startAdornment: (
                                    <PasswordIcon sx={{ margin: "0 10px 0 0" }} />
                                ),
                            }}
                            name="password1"
                            onChange={onChange}
                        />

                        <TextField
                            label="Password"
                            fullWidth
                            variant="standard"
                            required
                            type="password"
                            InputProps={{
                                startAdornment: (
                                    <PasswordIcon sx={{ margin: "0 10px 0 0" }} />
                                ),
                            }}
                            name="password2"
                            onChange={onChange}
                        />

                        <Button
                            text="Login"
                            clicked={resetPasswordAction}
                            icon={undefined}
                        />
                    </form>
                </div>
            ) : (
                <></>
            )}
            <CustomizedSnackbars messageType={snackBarData.messageType} message={snackBarData.message} openSnackbar={snackBarData.openSnackbar} key={snackBarData.key} />
        </>
    )
}

export default ResetPassword
