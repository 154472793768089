import React, { useEffect, useState } from 'react';
import './Banner.css';

import FilmCards from '../FilmCard/FilmCard';
import { useDragScroll } from '../../Components/Dragger';
import { ISearchFilm } from '../../Interfaces/FilmSearch';
import { IFilm } from '../../Interfaces/Film';

import Settings from "../../Utils/Routes.json";
import CircularProgress from '../../Components/CircularProgress';
import { getCountries } from '../../Utils/Requests/RequestRegister';
import { ICountry } from '../../Interfaces/Country';

function Banner() {
    const [hoveredFilm, setHoveredFilm] = useState<IFilm | undefined>();
    const [backgroundImage, setBackgroundImage] = useState<string>('');
    const [selectedFilmScore, setSelectedFilmScore] = useState<number>(0);
    const [selectedFilmColor, setSelectedFilmColor] = useState<string>('');
    const [countries, setCountries] = useState<ICountry[]>([]);

    const filmData: ISearchFilm = {
        page: 1,
        limit: 9,
        skeletonAmount: 9,
        sort: 'new',
    };

    const [draggerRef] = useDragScroll();

    const loadCountries = async () => {
        try {
            const response = await getCountries();

            if (response.status === "error") {
                console.error('Error loading countries:', response.message);
                return;
            }

            const countries: ICountry[] = response.countries;

            setCountries(response.countries)
        } catch (error) {
            console.error('Error loading countries:', error);
        }
    };


    const getFilmCountry = (countryId: number | undefined) : string => {

        if (!countryId) {
            return '';
        }

        const country: ICountry | undefined = countries.find((country) => country.id == countryId);

        if (!country) {
            return ""
        }

        return country.country_name
    }


    const handleFilmHover = (film: IFilm) => {
        setBackgroundImage(`url(${Settings.Domain + Settings.imagesFilm + film.film_banner})`);

        if (typeof film.like === 'number' && typeof film.dislike === 'number') {
            calculateScore(film.like, film.dislike);
        }

        setHoveredFilm(film);
    };

    const calculateScore = (likes: number, dislikes: number) => {
        const total = likes + dislikes;

        // If no likes or dislikes, set score to 0 and color to red
        if (total === 0) {
            setSelectedFilmScore(0);
            setSelectedFilmColor('rgba(255, 0, 0, 0.5)'); // Red with 50% opacity
            return;
        }

        // Calculate score and round it to 1 decimal place
        const score = (likes / total) * 10;
        const scoreParsed = parseFloat(score.toFixed(1));
        setSelectedFilmScore(scoreParsed);

        // Calculate color based on the score
        const color = calculateColor(scoreParsed);
        setSelectedFilmColor(color);
    };

    const calculateColor = (score: number): string => {
        // RGB color progression from red to green
        const red = score < 5 ? 255 : Math.round(255 - (score - 5) * 51);
        const green = score > 5 ? 255 : Math.round(score * 51);
        return `rgba(${red}, ${green}, 0, 0.5)`; // 50% opacity
    };

    useEffect(() => {
        loadCountries()
    }, [])

    return (
        <div className='banner-skeleton' style={{ backgroundImage: hoveredFilm ? backgroundImage : "", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
            <div className='spacer'></div>

            <div className='content'>
                <div className='film-info'>
                    <h2 className='film-title'>
                        {hoveredFilm?.film_name}
                        <span style={{ color: "gray" }}> ({hoveredFilm?.film_release})</span>
                    </h2>

                    <div className='film-score'>
                        <CircularProgress score={selectedFilmScore} color={selectedFilmColor} size={48} thickness={4} />
                    </div>

                    <p  className='film-country' style={{ color: "gray" }}>{getFilmCountry(hoveredFilm?.film_country)}</p>
                    <p  className='film-description' style={{ color: "gray" }}>{hoveredFilm?.film_description}</p>
                </div>
                <div className='film-list' ref={draggerRef}>
                    <FilmCards filmData={filmData} horizontal={true} className="banner" onHover={handleFilmHover} play={true} speed={5000} />
                </div>
            </div>
            <div className='shadow'></div>
        </div>
    )
}

export default Banner;
