import React, { useState } from 'react'

import { ISnackbar } from '../../../../Interfaces/Snackbar';
import { CustomizedSnackbars } from '../../../../Design/Snackbar/Snackbar';
import Button from '../../../../Design/Button/Button';
import { TextField } from '@mui/material';
import { deleteFilmRequest } from '../../../../Utils/Requests/RequestRegister';
import { useAuth } from '../../../../Components/AuthProvider';

export default function DeleteFilm() {

  const { user } = useAuth()

  const [snackBar, setSnackBar] = useState<ISnackbar>({
    message: '',
    messageType: '',
    openSnackbar: false,
    key: 0
  })

  const [filmId, setFilmId] = useState<number>(0)

  const deleteFilm = async (e: any, callback: () => void) => {
    try {
      const response = await deleteFilmRequest({
        film_id: filmId,
        token: user.token
      })

      if (response.status === "success") {
        setFilmId(0)
        setSnackBar({
          openSnackbar: true,
          messageType: "success",
          message: "Film deleted successfully",
          key: Math.random()
        })
        callback()
        return
      } else {
        setSnackBar({
          openSnackbar: true,
          messageType: "error",
          message: response.message,
          key: Math.random()
        })
      }

      callback()
    } catch (error) {
      setSnackBar({
        openSnackbar: true,
        messageType: "error",
        message: "Something went wrong",
        key: Math.random()
      })
      callback()
    }
  }


  return (
    <>
      <TextField
        label="Film ID"
        fullWidth
        variant="standard"
        required
        type="number"
        onChange={(e) => {
          setFilmId(parseInt(e.target.value))
        }}
        sx={{ minWidth: 400 }}
        value={filmId} />

      <Button text='Load Film' clicked={deleteFilm}></Button>


      <CustomizedSnackbars
        openSnackbar={snackBar.openSnackbar}
        message={snackBar.message}
        messageType={snackBar.messageType}
        key={snackBar.key}
      />
    </>
  )
}
