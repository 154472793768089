import React, { useState } from 'react'
import { removePeopleDataRequest } from '../../../../Utils/Requests/RequestRegister';
import { useAuth } from '../../../../Components/AuthProvider';
import Button from '../../../../Design/Button/Button';
import { ISnackbar } from '../../../../Interfaces/Snackbar';
import { CustomizedSnackbars } from '../../../../Design/Snackbar/Snackbar';
import { TextField } from '@mui/material';

export default function DeletePeople() {

  const { user } = useAuth();

  const [userId, setUserId] = useState(0)

  const [snackBarData, setSnackBarData] = useState<ISnackbar>({
    message: '',
    messageType: '',
    openSnackbar: false,
    key: 0
  });

  const deteleUser = async (e: React.MouseEvent, callback: () => void) => {
    try {

      const response = await removePeopleDataRequest({
        id: userId,
        token: user.token
      });

      if (response.status === 'error') {
        setSnackBarData({
          message: response.message,
          messageType: 'error',
          openSnackbar: true,
          key: Date.now()
        });
      }

      if (response.status ==='success') {
        setSnackBarData({
          message: 'User deleted successfully',
          messageType:'success',
          openSnackbar: true,
          key: Date.now()
        });

        setUserId(0)
      }

    } catch (error) {
      setSnackBarData({
        message: 'Error while deleting user',
        messageType: 'error',
        openSnackbar: true,
        key: Date.now()
      });

      
      setUserId(0)
    }

    callback()
  }


  return (
    <>
      <div className='load-people-form'>
        <TextField
          label="Enter ID"
          fullWidth
          variant="standard"
          type="text"
          value={userId}
          onChange={(e: any) => setUserId(e.target.value)}
        />
        <Button text='Delete' clicked={deteleUser} />
      </div>

      <CustomizedSnackbars
        messageType={snackBarData.messageType}
        message={snackBarData.message}
        openSnackbar={snackBarData.openSnackbar}
        key={snackBarData.key}
      />
    </>
  )
}
