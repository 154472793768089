import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import AutorenewIcon from '@mui/icons-material/Autorenew';
import './Auth.css';
import { verifyEmailRequest } from "../../Utils/Requests/RequestRegister";


export default function UserVerifyMail() {
    
    const [searchParams, setSearchParams] = useSearchParams();
    const token: string | null = searchParams.get("token");

    const [resultData, setResultData] = useState<{
        success: boolean,
        message: string,
        error: string | null,
        loaded: boolean,
    }>({
        success: false,
        message: "Loading...",
        error: null,
        loaded: false,
    });


    useEffect(() => {
        if (token) {
            const data = verifyEmailRequest({
                token: token
            })

            data.then((response) => {
                if (response.status === "success") {
                    setResultData({
                        success: true,
                        message: response.message,
                        error: null,
                        loaded: true,
                    });
                } else {
                    setResultData({
                        success: false,
                        message: response.message,
                        error: null,
                        loaded: true,
                    });
                }
            })
            .catch((error) => {
                setResultData({
                    success: false,
                    message: "An error occurred",
                    error: null,
                    loaded: true,
                });
            })
        } else {
            setResultData({
                success: false,
                message: "No token provided",
                error: null,
                loaded: true,
            });
        }
    }, [token])

    return (
        <div className="auth-form-holder">
            <form className="login-form">
                {token === null ? (
                    <h1>No token provided</h1>
                ) : (
                    <>
                        <div className="verify_email">
                            {resultData.loaded ? (
                                resultData.success ? (
                                    <div className="success">
                                        <h1>Email verified successfully!</h1>
                                        <p>You can now log in with your registered email.</p>
                                    </div>
                                ) : (
                                    <div className="error">
                                        <h1>Email verification failed</h1>
                                        <p>{resultData.error ?? "An error occurred"}</p>
                                    </div>
                                )
                            ) : (
                                <>
                                    <h1 style={{color: "white"}}>Verifying Email</h1>
                                    <AutorenewIcon className="loading-icon" />
                                </>
                            )}
                        </div>
                    </>
                )}
            </form>
        </div>
    );
}
