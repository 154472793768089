import { post, get } from "./RequestHandler";
import Routes from "../Routes.json";

function registerUserRequest(data: any): Promise<any> {
    return post(Routes.registerUser, data)
}

function checkUserTokenRequest(data: any): Promise<any> {
    return post(Routes.verifyToken, data)
}

function loginUserRequest(data: any): Promise<any> {
    return post(Routes.loginUser, data)
}

function searchFilmDataRequest(data: any): Promise<any> {
    return get(Routes.searchFilms + data)
}

function resetPasswordRequest(data: any): Promise<any> {
    return post(Routes.resetPassword, data)
}

function resetUserPasswordRequest(data: any): Promise<any> {
    return post(Routes.resetUserPassword, data)
}

function sendMailVerificationRequest(data: any): Promise<any> {
    return post(Routes.sendMailVerification, data)
}

function saveUserDataRequest(data: any): Promise<any> {
    return post(Routes.saveUserData, data)
}

function verifyEmailRequest(data: any): Promise<any> {
    return post(Routes.verifyEmail, data)
}

// People

function addPeopleDataRequest(data: any): Promise<any> {
    return post(Routes.addPeople, data)
}

function getPeopleDataRequest(data: string): Promise<any> {
    return get(Routes.getPeople + data)
}

function updatePeopleDataRequest(data: any): Promise<any> {
    return post(Routes.updatePeople, data)
}

function removePeopleDataRequest(data: any): Promise<any> {
    return post(Routes.deletePeople, data)
}

// Utils

function getCountries(): Promise<any> {
    return get(Routes.getCountries)
}

function getGenres(): Promise<any> {
    return get(Routes.getGenres)
}

// Film

function AddFilmDataRequest(data: any): Promise<any> {
    return post(Routes.addFilm, data)
}

function getFilmDataRequest(data: string): Promise<any> {
    return get(Routes.getFilm + data)
}

function editFilmDataRequest(data: any): Promise<any> {
    return post(Routes.editFilm, data)
}

// Film Vote

function removeFilmToPlaylistRequest(data: any): Promise<any> {
    return post(Routes.removeFilmFromPlaylist, data)
}

function createUserPlaylistRequest(data: any): Promise<any> {
    return post(Routes.createUserPlaylistRequest, data)
}

function getUserPlaylistRequest(data: any): Promise<any> {
    return get(Routes.getUserPlaylistRequest + data)
}

function voteFilmRequest(data: any): Promise<any> {
    return post(Routes.voteFilmRequest, data)
}

function deletePlaylistRequest(data: any): Promise<any> {
    return post(Routes.deletePlaylistRequest, data)
}

function addFilmToPlaylistRequest(data: any): Promise<any> {
    return post(Routes.addFilmToPlaylistRequest, data)
}

function deleteFilmRequest(data: any): Promise<any> {
    return post(Routes.deteleFilm, data)
}

export {
    registerUserRequest,
    checkUserTokenRequest,
    loginUserRequest,
    searchFilmDataRequest,
    getCountries,
    addPeopleDataRequest,
    getPeopleDataRequest,
    removePeopleDataRequest,
    updatePeopleDataRequest,
    getGenres,
    AddFilmDataRequest,
    getFilmDataRequest,
    removeFilmToPlaylistRequest,
    createUserPlaylistRequest,
    getUserPlaylistRequest,
    voteFilmRequest,
    deletePlaylistRequest,
    addFilmToPlaylistRequest,
    editFilmDataRequest,
    deleteFilmRequest,
    resetPasswordRequest,
    resetUserPasswordRequest,
    sendMailVerificationRequest,
    saveUserDataRequest,
    verifyEmailRequest
}