import "./TermOfService.css";

function TermOfService() {
    return (
        <div className="term-of-service">
            <h1>Terms of Service</h1>
            <p><em>Last Updated: 23/10/2024</em></p>
            <p>
                Welcome to FxFilms ("the Website"). By accessing or using our services, 
                you agree to be bound by these Terms of Service ("Terms"). If you do not 
                agree to these Terms, please do not use FxFilms.
            </p>

            <h2>1. Acceptance of Terms</h2>
            <p>
                By using FxFilms, you agree to comply with and be legally bound by these 
                terms and conditions. These Terms apply to all users of the Website, 
                including visitors and registered users.
            </p>

            <h2>2. Changes to the Terms</h2>
            <p>
                We reserve the right to modify these Terms at any time. Changes will be 
                effective immediately upon posting on FxFilms. Your continued use of the 
                Website after such changes constitutes your acceptance of the revised Terms.
            </p>

            <h2>3. User Eligibility</h2>
            <p>
                FxFilms is accessible to users of all ages. However, parents and guardians 
                are advised to supervise the use of the Website by minors.
            </p>

            <h2>4. User Accounts</h2>
            <ul>
                <li>You may be required to create a user account to access certain features of FxFilms. You are responsible for maintaining the confidentiality of your account information.</li>
                <li>You agree to provide accurate and complete information when creating your account, including a valid email, name, and password.</li>
                <li>We reserve the right to terminate your account if we suspect a breach of these Terms or any illegal activity.</li>
            </ul>

            <h2>5. Use of Content</h2>
            <ul>
                <li>All content on FxFilms, including films and images ("Content"), is provided for personal, non-commercial use only.</li>
                <li>You may not copy, distribute, modify, or publicly display any Content without our express written consent.</li>
            </ul>

            <h2>6. User Conduct</h2>
            <ul>
                <li>You agree not to use FxFilms for any unlawful purpose, including but not limited to:</li>
                <ul>
                    <li>Violating any intellectual property rights.</li>
                    <li>Engaging in spamming or fraudulent activities.</li>
                    <li>Attempting to hack or breach FxFilms' security.</li>
                </ul>
                <li>Downloading films or other materials from the Website is prohibited.</li>
            </ul>

            <h2>7. Advertisements</h2>
            <p>
                FxFilms is supported by advertisements. By using the Website, you agree to view ads as part of your experience. 
                We are not responsible for the content of any third-party ads displayed.
            </p>

            <h2>8. Data Collection and Privacy</h2>
            <ul>
                <li>We collect user information such as email, name, lastname, and hashed passwords for account management. For more details, please review our [Privacy Policy].</li>
                <li>We do not sell or share your personal information with third parties, except as required by law.</li>
            </ul>

            <h2>9. Disclaimer of Warranties</h2>
            <p>
                FxFilms is provided on an "as is" and "as available" basis. We do not guarantee that the Website will be error-free, uninterrupted, or free from viruses.
            </p>

            <h2>10. Limitation of Liability</h2>
            <p>
                We shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use or inability to use FxFilms.
            </p>

            <h2>11. Indemnification</h2>
            <p>
                You agree to indemnify and hold FxFilms, its affiliates, and employees harmless from any claims arising from your use of the Website or violation of these Terms.
            </p>

            <h2>12. Termination</h2>
            <p>
                We reserve the right to terminate or restrict your access to FxFilms for any reason, including breaches of these Terms.
            </p>

            <h2>13. Governing Law</h2>
            <p>
                These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Disputes arising under these Terms shall be subject to the jurisdiction of the courts in [Your Jurisdiction].
            </p>

            <h2>14. Contact Us</h2>
            <p>
                If you have any questions about these Terms, please contact us at [Insert Contact Email].
            </p>
        </div>
    );
}

export default TermOfService;
